import React, {useCallback, useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

import {Button, Checkbox, FormControlLabel, Grid, Modal, TextField} from "@material-ui/core";
import '../../../assets/css/quizCreation.css'
import {CheckCircle, CheckCircleOutline} from "@material-ui/icons";
import carreImage from '../../../assets/img/carreimg.png'
import {useDropzone} from "react-dropzone";

export default function Question({idQuiz, numero, startGoodAnswer, startIntitule, startReponse1, startReponse2, startReponse3, startReponse4, startIndice, startImageUrl, startImageFirebasePath, callback, nextButtonFunc, suppressButtonFunc}){

    const [goodAnswer, setGoodAnswer] = useState(startGoodAnswer);
    const [intitule, setIntitule] = useState(startIntitule);
    const [reponse1, setReponse1] = useState(startReponse1);
    const [reponse2, setReponse2] = useState(startReponse2);
    const [reponse3, setReponse3] = useState(startReponse3);
    const [reponse4, setReponse4] = useState(startReponse4);
    const [indice, setIndice] = useState(startIndice);
    const [imageUrl, setImageUrl] = useState(startImageUrl);
    const [imageFirebasePath, setImageFirebasePath] = useState(startImageFirebasePath);
    const [imageFile, setImageFile] = useState(undefined);

    const [open, setOpen] = useState(false);

    const myRef = useRef(null)

    const useStyles = makeStyles((theme) => ({
        paper: {
            padding: "5px",
            textAlign: 'center',
            color: "black",
        },
        paperCenter: {
            padding: "5px",
            textAlign: 'center',
            color: "black",
            backgroundColor : "#F6FAFD"
        },
        paperFooter : {
            padding: "15px",
        },
        paperButtons : {
            padding: "15px",
            display : "flex",
            justifyContent : "center"
        },
        questionTitle : {
            fontWeight : "bold",
            textAlign : "left"
        },
        fullWidthInput : {
            width : "100%",
            marginRight : "10px"
        },
        fullWidthInputContainer : {
            padding : "5px 25px",
            textAlign : "left",
        },
        fullWidthInputContainerResponse : {
            padding : "5px 0px 5px 25px",
            textAlign : "left",
        },
        goodAnswerCheck : {
            backgroundColor : "none",
            fontSize : "50px"
        },
        goodAnswerCheckIcon : {
            fontSize : "50px"
        },
        carreImage : {
            padding : "15px"
        },
        button : {
            margin : "15px",
            color : "white",
            backgroundColor : "#55BDBC",
            "&:hover": {
                backgroundColor : "white",
                color : "#55BDBC",
            },
        },
        img : {
            width: "auto",
            height: "auto",
            maxHeight: "150px"
        }
    }))
    const classes = useStyles();

    useEffect(() => {
        setGoodAnswer(startGoodAnswer);
        setIntitule(startIntitule);
        setReponse1(startReponse1);
        setReponse2(startReponse2);
        setReponse3(startReponse3);
        setReponse4(startReponse4);
        setIndice(startIndice);
        setImageUrl(startImageUrl);
        setImageFirebasePath(startImageFirebasePath);
        myRef.current.scrollIntoView({behavior: "smooth"});
    }, [numero, startGoodAnswer, startIntitule, startReponse1, startReponse2, startReponse3, startReponse4, startIndice, startImageUrl, startImageFirebasePath, myRef])

    const handleClose = () => {
        setOpen(false);
    }

    const handleCheckboxChange = (num) => {
        setGoodAnswer(num);
        callback(numero, num, intitule, reponse1, reponse2, reponse3, reponse4, indice, imageUrl, imageFile, imageFirebasePath);
    }

    const handleIntituleChange = (value) => {
        setIntitule(value);
        callback(numero, goodAnswer, value, reponse1, reponse2, reponse3, reponse4, indice, imageUrl, imageFile, imageFirebasePath);
    }

    const handleReponse1Change = (value) => {
        setReponse1(value);
        callback(numero, goodAnswer, intitule, value, reponse2, reponse3, reponse4, indice, imageUrl, imageFile, imageFirebasePath);
    }

    const handleReponse2Change = (value) => {
        setReponse2(value);
        callback(numero, goodAnswer, intitule, reponse1, value, reponse3, reponse4, indice, imageUrl, imageFile, imageFirebasePath);
    }

    const handleReponse3Change = (value) => {
        setReponse3(value);
        callback(numero, goodAnswer, intitule, reponse1, reponse2, value, reponse4, indice, imageUrl, imageFile, imageFirebasePath);
    }

    const handleReponse4Change = (value) => {
        setReponse4(value);
        callback(numero, goodAnswer, intitule, reponse1, reponse2, reponse3, value, indice, imageUrl, imageFile, imageFirebasePath);
    }

    const handleIndiceChange = (value) => {
        setIndice(value);
        callback(numero, goodAnswer, intitule, reponse1, reponse2, reponse3, reponse4, value, imageUrl, imageFile, imageFirebasePath);
    }

    const handleImageChange = (url, file) => {
        setImageUrl(url);
        setImageFile(file)
        callback(numero, goodAnswer, intitule, reponse1, reponse2, reponse3, reponse4, indice, url, file, imageFirebasePath);
    }

    return(
        <>
            <Grid item xs={1}>
                <div className={classes.paper}> </div>
            </Grid>
            <Grid item xs={10} ref={myRef}>
                <div className={classes.paperCenter}>
                    <Grid container spacing={0} className={classes.root}>
                        <Grid item xs={12}>
                            <h3 className={classes.questionTitle}>Question {numero}</h3>
                        </Grid>
                        <Grid item xs={12} className={classes.fullWidthInputContainer}>
                            <TextField id="outlined-basic" label="Intitulé" variant="outlined" className={classes.fullWidthInput} value={intitule} onInput={(e) => {handleIntituleChange(e.target.value); }}/>
                        </Grid>
                        <Grid item xs={12} style={{
                            cursor : "pointer",
                            backgroundColor: "rgba(0,0,0,0.05)",
                            margin: "5px 25px",
                            borderRadius: "10px"
                        }}>
                            <Dropzone callbackImageChange={handleImageChange} startImage={imageUrl}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5} className={classes.fullWidthInputContainerResponse}>
                            <h6>Réponse 1</h6>
                            <div style={{display : "flex", alignItems : "center"}}>
                                <TextField id="outlined-basic" label="" variant="outlined" className={classes.fullWidthInput} value={reponse1} onInput={(e) => {handleReponse1Change(e.target.value); }}/>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={<CheckCircleOutline className={classes.goodAnswerCheckIcon}/>}
                                            checkedIcon={<CheckCircle className={classes.goodAnswerCheckIcon}/>}
                                            name="checkedH"
                                            className={classes.goodAnswerCheck}
                                            onChange={() => {handleCheckboxChange(1)}}
                                            checked={goodAnswer === 1}
                                        />
                                    }
                                    label=""
                                />
                            </div>
                        </Grid>
                        <Grid item xs={0} sm={0} md={2}>
                            <p> </p>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5} className={classes.fullWidthInputContainerResponse}>
                            <h6>Réponse 2</h6>
                            <div style={{display : "flex", alignItems : "center"}}>
                                <TextField id="outlined-basic" label="" variant="outlined" className={classes.fullWidthInput} value={reponse2} onInput={(e) => {handleReponse2Change(e.target.value); }}/>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={<CheckCircleOutline className={classes.goodAnswerCheckIcon}/>}
                                            checkedIcon={<CheckCircle className={classes.goodAnswerCheckIcon}/>}
                                            name="checkedH"
                                            className={classes.goodAnswerCheck}
                                            onChange={() => {handleCheckboxChange(2)}}
                                            checked={goodAnswer === 2}
                                        />
                                    }
                                    label=""
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5} className={classes.fullWidthInputContainerResponse}>
                            <h6>Réponse 3 (optionnelle)</h6>
                            <div style={{display : "flex", alignItems : "center"}}>
                                <TextField id="outlined-basic" label="" variant="outlined" className={classes.fullWidthInput} value={reponse3} onInput={(e) => {handleReponse3Change(e.target.value); }}/>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={<CheckCircleOutline className={classes.goodAnswerCheckIcon}/>}
                                            checkedIcon={<CheckCircle className={classes.goodAnswerCheckIcon}/>}
                                            name="checkedH"
                                            className={classes.goodAnswerCheck}
                                            onChange={() => {handleCheckboxChange(3)}}
                                            checked={goodAnswer === 3}
                                        />
                                    }
                                    label=""
                                />
                            </div>
                        </Grid>
                        <Grid item xs={0} sm={0} md={2}>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5} className={classes.fullWidthInputContainerResponse}>
                            <h6>Réponse 4 (optionnelle)</h6>
                            <div style={{display : "flex", alignItems : "center"}}>
                                <TextField id="outlined-basic" label="" variant="outlined" className={classes.fullWidthInput} value={reponse4} onInput={(e) => {handleReponse4Change(e.target.value); }}/>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={<CheckCircleOutline className={classes.goodAnswerCheckIcon}/>}
                                            checkedIcon={<CheckCircle className={classes.goodAnswerCheckIcon}/>}
                                            name="checkedH"
                                            className={classes.goodAnswerCheck}
                                            onChange={() => {handleCheckboxChange(4)}}
                                            checked={goodAnswer === 4}
                                        />
                                    }
                                    label=""
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} className={classes.fullWidthInputContainerResponse}>
                            <h6>Indice en cas de mauvaise réponse (optionnel)</h6>
                            <div style={{display : "flex", alignItems : "center"}}>
                                <TextField id="outlined-basic" label="" variant="outlined" className={classes.fullWidthInput} value={indice} onInput={(e) => {handleIndiceChange(e.target.value); }}/>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={1}>
                <div className={classes.paper}> </div>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.paperButtons}>
                    {
                        nextButtonFunc !== undefined &&
                        <Button variant="contained" color="primary" style={{borderRadius : "25px", backgroundColor : "#55bdbc", color : "white"}} size="large" onClick={() => {nextButtonFunc();}} className={classes.button}>
                            Ajouter une question
                        </Button>
                    }
                    {
                        suppressButtonFunc !== undefined &&
                        <Button variant="contained" color="primary" style={{borderRadius : "25px", backgroundColor : "#55bdbc", color : "white"}} size="large" onClick={() => setOpen(true)} className={classes.button}>
                            Supprimer cette question
                        </Button>
                    }
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.paperFooter}> </div>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >

                <div style={{display : "flex", justifyContent : "center", height : "100%", alignItems : "center"}}>
                    <div
                        style={{
                            backgroundColor : "white",
                            width : "60%",
                            borderRadius : "25px",
                            padding : "20px"
                        }}
                    >
                        <a style={{position : "relative", top : "-40px", left : "-35px", fontSize : "40px", color : "white"}} onClick={handleClose}>
                            <i className="fas fa-circle"/>
                            <i className="fas fa-times-circle" style={{position : "relative", left : "-45px", color : "red", fontSize : "50px"}}/>
                        </a>
                        <div style={{textAlign : "center"}}>
                            <h2 style={{fontWeight : "bold", color : "red"}}>Attention !</h2>
                            <p>Etes-vous sûr de vouloir supprimer cette question ?<br/>Vous ne pourrez pas la récupérer.</p>
                        </div>
                        <div style={{textAlign : "center", width : "100%", paddingBottom : "50px"}}>
                            <Button style={{minWidth : "150px", margin : "15px"}} variant="contained" color="primary" size="large" onClick={handleClose}>
                                Non
                            </Button>
                            <Button style={{minWidth : "150px", margin : "15px"}} variant="contained" color="primary" size="large" onClick={() => {suppressButtonFunc(numero); handleClose()}}>
                                Oui
                            </Button>
                        </div>

                    </div>
                </div>
            </Modal>
        </>
    )
}

function Dropzone({callbackImageChange, startImage}) {

    const [image, setImage] = useState(startImage ? startImage : carreImage);

    const onDrop = useCallback(async (acceptedFiles) => {
        try{
            let file = acceptedFiles[0]
            let url = URL.createObjectURL(file);
            setImage(url)
            callbackImageChange(url, file)
        }catch(e){
            console.log(e);
        }
    }, [callbackImageChange]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: 'image/*',
        onDrop: onDrop
    });

    const useStyles = makeStyles((theme) => ({
        img : {
            padding : "15px",
            display: 'block',
            width : "100%",
            height: '100%',
            maxWidth : "208px",
            maxHeight : "146px"
        }
    }))
    const classes = useStyles();


    useEffect(() => {
        setImage(startImage)
    }, [startImage])

    return (
        <div {...getRootProps()} style={{display: "flex", justifyContent : "center", width : "100%"}}>
            <input {...getInputProps()}/>
            {
                isDragActive ?
                    <p><img alt="Glissez ici" src={carreImage} className={classes.img}/></p> :
                    <img  alt="Glissez ici"  src={image} className={classes.img}/>
            }
        </div>
    )
}