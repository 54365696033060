import React, {useEffect, useState} from 'react'
import {MDBCol, MDBIcon, MDBInput, MDBRow} from "mdbreact";
import {useHistory} from "react-router-dom";
import {Button, Checkbox, Input, ListItemText, MenuItem, Select} from "@material-ui/core";

import metierConfig from "./metier.config.json";
import firebaseConnector from "../api/firebase-connector";
import svgLogin from "../assets/img/svg/login-svg.svg";
import '../assets/css/sign.css'

export default function SignUpPage(){
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConf, setPasswordConf] = useState("")
    const [prenom, setPrenom] = useState("")
    const [profession, setProfession] = useState("")

    const [isValidate, setIsValidate] = useState(false)

    const [isPending, setIsPending] = useState(false)

    let history = useHistory();

    useEffect(()=>{
        setIsValidate(email!=="" && password!=="" && passwordConf===password && prenom!=="" && profession!=="")
    }, [email, password, passwordConf, prenom, profession])

    const handleSubmit = async (event) =>{
        event.preventDefault()
        setIsPending(true);
        const authUser = await firebaseConnector.auth().createUserWithEmailAndPassword(email, password);

        console.log(authUser);
        await firebaseConnector.firestore().collection("user_information").doc(authUser.user.uid).set({
            user_id : authUser.user.uid,
            prenom : prenom,
            profession : profession
        });

        history.push("/");
        setIsPending(false);
    }

    return(
        <div>
            <MDBRow style={{marginLeft: "0px", marginRight: "0px", height: "100vh", justifyContent: "center", backgroundColor: "#55BDBC"}}>
                <MDBCol md="1"/>
                <MDBCol md="4" className="container" style={{alignItems: "center", display: "grid", color : "white"}}>
                    <MDBRow>
                        <MDBCol size="10">
                            <form onSubmit={handleSubmit}>
                                <div className="signin">
                                    <h2 style={{textAlign: "center", color : "white", fontWeight: "bold"}}>Inscription</h2>
                                    <br/>

                                    <MDBInput
                                        focused
                                        size="md"
                                        label="Adresse e-mail"
                                        getValue={(value)=>{setEmail(value)}}
                                        icon="envelope"
                                        value={email}
                                        iconLight={true}
                                        type="email"
                                        style={{fontSize: "1.2rem"}}
                                    />

                                    <MDBInput
                                        size="md"
                                        value={password}
                                        getValue={(value)=>{setPassword(value)}}
                                        label="Mot de passe"
                                        icon="lock"
                                        iconLight={true}
                                        type="password"
                                        style={{fontSize: "1.2rem"}}
                                    />

                                    <MDBInput
                                        size="md"
                                        value={passwordConf}
                                        getValue={(value)=>{setPasswordConf(value)}}
                                        label="Confirmation"
                                        icon="lock"
                                        iconLight={true}
                                        type="password"
                                        style={{fontSize: "1.2rem"}}
                                    />

                                    <MDBInput
                                        size="md"
                                        value={prenom}
                                        getValue={(value)=>{setPrenom(value)}}
                                        label="Prénom"
                                        icon="user-edit"
                                        iconLight={true}
                                        style={{fontSize: "1.2rem"}}
                                    />

                                    <p><i style={{fontSize: "1.75rem"}} className="fal fa-user-edit"/> Profession</p>
                                    <Select
                                        style={{fontSize: "1.2rem", width : "100%"}}
                                        id="profSelect"
                                        multiple={false}
                                        value={profession}
                                        onChange={(value)=>{console.log(value.target.value);setProfession(value.target.value)}}
                                        input={<Input />}
                                        renderValue={profession => (<p style={{color : "#55BDBC", margin : "5px 5px 5px 15px"}}>{profession}</p>)}
                                    >
                                        {metierConfig.professions.map((professionObj, index) => (
                                            <MenuItem key={index} value={professionObj.profession}>
                                                <Checkbox checked={professionObj.profession === profession} />
                                                <ListItemText primary={professionObj.profession} />
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    <div className="text-center" style={{marginTop: "2rem"}}>
                                        <Button variant="contained" type="submit" color="primary" disabled={!isValidate} style={{borderRadius : "25px", backgroundColor : (!isValidate ? '#0000001e' : "white"), color : (!isValidate ? 'rgba(0,0,0,0.118)' : "#55bdbc")}} size="large">
                                            Inscription {isPending && <MDBIcon icon="spinner" pulse size="3x" fixed />}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol md="6" style={{backgroundColor: "#FFFFFF", textAlign: "center", display: "flex", flexDirection : "column", justifyContent : "center"}}>
                    <p style={{color : "#55BDBC", fontWeight : "bold", width : "100%", textAlign : "center", fontSize : "50px"}}>Pupylle</p>
                    <img src={svgLogin} alt="login-svg" className="img-fluid"/>
                </MDBCol>
            </MDBRow>
        </div>
    );
}