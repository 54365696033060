import React, {useEffect, useState} from 'react';
import {
    MDBAnimation,
    MDBCol,
    MDBIcon,
    MDBInput,
    MDBRow,
    MDBSpinner,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from 'mdbreact';

import Button from "@material-ui/core/Button";
import FilterSearch from "../../components/FilterSearch";
import {makeStyles} from "@material-ui/core/styles";
import {Fab, Grid, Modal} from "@material-ui/core";
import firebaseConnector from "../../api/firebase-connector";
import {KeyboardArrowDownRounded, KeyboardArrowUpRounded, PlayCircleOutlineRounded} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import metierConfig from "../../pages/metier.config.json";
import '../../assets/css/dashboard.css'
import imageTutoOrthophoniste from "../../assets/img/tuto/Suivi-1-4.gif";
import imageTutoEnseignant from "../../assets/img/tuto/Suivi-1-2.gif";
import imageTutoFle from "../../assets/img/tuto/Suivi-1-3.gif";
import imageTuto2 from "../../assets/img/tuto/Suivi-1.gif";

export default function Suivi(){
    let history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [filterSearch, setFilterSearch] = useState("");
    const [orderBy, setOrderBy] = useState("nom");
    const [alphabeticalOrder, setAlphabeticalOrder] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [openTuto, setOpenTuto] = useState(false);

    const [nomEleve, setNomEleve] = useState("");
    const [prenomEleve, setPrenomEleve] = useState("");
    const [isValidateEleve, setIsValidateEleve] = useState(false)

    const [eleveTab, setEleveTab] = useState([])
    const [nameEleve, setNameEleve] = useState("élève");

    const [isPending, setIsPending] = useState(false)

    const useStyles = makeStyles(() => ({
        dataColumn : {
            width : "40%"
        },
        buttonColumn : {
            width : "20%",
            textAlign : "right"
        }
    }))
    const classes = useStyles();

    const prepare = async () => {

        let user = firebaseConnector.auth().currentUser;

        let refTutos = firebaseConnector.firestore().collection("user_information").doc(user.uid).collection("tutorials").where("type", "==", "Suivis");
        let queryTutos = await refTutos.get();
        if(queryTutos.empty){
            setOpenTuto(true);
        }

        let userDoc = await firebaseConnector.firestore().collection("user_information").doc(user.uid).get();
        if(userDoc.exists){
            setNameEleve(metierConfig.nomEleve[userDoc.data().profession]);
        }

        const eleves = await firebaseConnector.firestore().collection("user_information").doc(user.uid).collection("eleves").orderBy(orderBy, alphabeticalOrder ? 'asc' : 'desc').get();
        let newEleveTab = [];
        for(let eleve of eleves.docs){
            let add = true;
            if(filterSearch !== "") {
                let filters = filterSearch.split(" ");
                filters.forEach((filter) => {
                    if(filter !== "") {
                        if (!(eleve.data().nom.toLowerCase().includes(filter.toLowerCase()) || eleve.data().prenom.toLowerCase().includes(filter.toLowerCase()))) {
                            add = false;
                        }
                    }
                })
            }
            if(add){
                let eleveObj = eleve.data();
                eleveObj.id = eleve.id
                newEleveTab.push(eleveObj);
            }
        }
        setEleveTab([...newEleveTab]);

        setIsLoading(false)
    }


    useEffect(()=>{
        prepare();
    }, [filterSearch, alphabeticalOrder, orderBy])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        prepare();
    };

    const handleAddEleve = async () => {
        setIsPending(true);

        let user = firebaseConnector.auth().currentUser;
        await firebaseConnector.firestore().collection("user_information").doc(user.uid).collection("eleves").add({
            nom :  nomEleve,
            prenom : prenomEleve,
            creationTime : firebaseConnector.firestore.Timestamp.fromDate(new Date())
        });

        setNomEleve("");
        setPrenomEleve("");
        handleClose();
        setIsPending(false);
    }

    const handleChangeNomEleve = (value)=>{
        setNomEleve(value);
        if(value === "" || prenomEleve === ""){
            setIsValidateEleve(false)
        }else{
            setIsValidateEleve(true)
        }
    }

    const handleChangePrenomEleve = (value)=>{
        setPrenomEleve(value);
        if(nomEleve === "" || value === ""){
            setIsValidateEleve(false)
        }else{
            setIsValidateEleve(true)
        }
    }

    const handleHeaderClick = (value) => {
        if (value === orderBy) {
            setAlphabeticalOrder(!alphabeticalOrder)
        }else{
            setAlphabeticalOrder(true);
            setOrderBy(value)
        }
    }

    const formatFirebaseTimestamp = (timestamp) => {
        let dateObj;

        try {
            dateObj = timestamp.toDate();
        }catch (e) {
            dateObj = new Date();
        }

        let jour = dateObj.getDate();
        if(jour < 10){
            jour = "0" + jour;
        }

        let mois = dateObj.getMonth() + 1;
        if(mois < 10){
            mois = "0" + mois;
        }

        let hours = dateObj.getHours();
        if(hours < 10){
            hours = "0" + hours;
        }

        let minutes = dateObj.getMinutes() + 1;
        if(minutes < 10){
            minutes = "0" + minutes;
        }

        return jour + "/" + mois + "/" + dateObj.getFullYear() + " " + hours + ":" + minutes;
    }

    const handleCloseTuto = async () => {
        let userId = firebaseConnector.auth().currentUser.uid;
        await firebaseConnector.firestore()
            .collection("user_information").doc(userId)
            .collection("tutorials").add({
                type : "Suivis"
            });
        setOpenTuto(false);
    }

    if(isLoading)
        return (
            <div className="mt-5">
                <MDBSpinner/>
            </div>
        )

    return(
        <div>
            <div>
                <MDBRow>
                    <MDBCol size="12" className="pl-0">
                        <MDBRow>
                            <MDBCol size="8">
                                <FilterSearch
                                    placeholder="Rechercher par nom ou prénom"
                                    value={filterSearch}
                                    handle={setFilterSearch}
                                />
                            </MDBCol>
                            <MDBCol size="4" style={{textAlign : 'right'}}>
                                <Button variant="contained" color="primary" style={{borderRadius : "25px", backgroundColor : "#55bdbc", color : "white"}} size="large" onClick={handleOpen}>
                                    Ajouter un {nameEleve}
                                </Button>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    className={classes.modal}
                                >

                                    <div style={{display : "flex", justifyContent : "center", height : "100%", alignItems : "center"}}>
                                        <div
                                            style={{
                                                backgroundColor : "white",
                                                width : "50%",
                                                borderRadius : "25px",
                                                padding : "20px"
                                            }}
                                        >
                                            <a style={{position : "relative", top : "-40px", left : "-35px", fontSize : "40px", color : "white"}} onClick={handleClose}>
                                                <i className="fas fa-circle"/>
                                                <i className="fas fa-times-circle" style={{position : "relative", left : "-45px", color : "red", fontSize : "50px"}}/>
                                            </a>
                                            <div className="dashboard">
                                                <h2 style={{fontWeight : "bold", textAlign : "center", color : "#0F3F62"}}>{"aeiouy".indexOf(nameEleve[0]) !== -1 ? "Nouvel" : "Nouveau"} {nameEleve}</h2>
                                                <MDBInput
                                                    size="md"
                                                    value={nomEleve}
                                                    getValue={(value)=>{handleChangeNomEleve(value)}}
                                                    label="Nom"
                                                    icon="user-edit"
                                                    iconLight={true}
                                                    style={{fontSize: "1.2rem"}}
                                                />
                                                <MDBInput
                                                    size="md"
                                                    value={prenomEleve}
                                                    getValue={(value)=>{handleChangePrenomEleve(value)}}
                                                    label="Prénom"
                                                    icon="user-edit"
                                                    iconLight={true}
                                                    style={{fontSize: "1.2rem"}}
                                                />
                                            </div>
                                            <div style={{textAlign : "center", width : "100%"}}>
                                                <Button variant="contained" color="primary" disabled={!isValidateEleve} style={{borderRadius : "25px", backgroundColor : (!isValidateEleve ? '#0000001e' : "#55bdbc"), color : (!isValidateEleve ? 'rgba(0,0,0,0.118)' : "white")}} size="large" onClick={handleAddEleve}>
                                                    {isPending && <MDBIcon icon="spinner" pulse size="3x" fixed />}Ajouter
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
            <div className="dashboard-content card z-depth-0dot5" style={{padding: "1rem"}}>
                <MDBAnimation type="fadeIn">
                    <MDBTable className="table-head-bold" style={{padding: "0.55rem"}}>
                        <MDBTableHead color="primary">
                            <tr style={{paddingLeft: "2rem"}}>
                                <th><a onClick={() => handleHeaderClick("nom")}>Nom {orderBy === "nom" ? alphabeticalOrder ? (<KeyboardArrowDownRounded/>) : (<KeyboardArrowUpRounded/>) : <KeyboardArrowUpRounded style={{color : "white"}}/>}</a></th>
                                <th><a onClick={() => handleHeaderClick("prenom")}>Prénom {orderBy === "prenom" ? alphabeticalOrder ? (<KeyboardArrowDownRounded/>) : (<KeyboardArrowUpRounded/>) : <KeyboardArrowUpRounded style={{color : "white"}}/>}</a></th>
                                <th><a onClick={() => handleHeaderClick("creationTime")}>Date d'ajout {orderBy === "creationTime" ? alphabeticalOrder ? (<KeyboardArrowDownRounded/>) : (<KeyboardArrowUpRounded/>) : <KeyboardArrowUpRounded style={{color : "white"}}/>}</a></th>
                                <th> </th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {
                                eleveTab.length > 0 && eleveTab.map(eleve => (
                                    <tr>
                                        <td className={classes.dataColumn} style={{verticalAlign : "middle"}}>{eleve.nom}</td>
                                        <td className={classes.dataColumn} style={{verticalAlign : "middle"}}>{eleve.prenom}</td>
                                        <td className={classes.dataColumn} style={{verticalAlign : "middle"}}>{formatFirebaseTimestamp(eleve.creationTime)}</td>
                                        <td className={classes.buttonColumn}>
                                            <Button variant="contained" color="primary" style={{borderRadius : "25px", backgroundColor : "#55bdbc", color : "white"}} size="large" onClick={() => {history.push("/suivi/" + eleve.id)}}>
                                                Résultats
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </MDBTableBody>
                    </MDBTable>
                </MDBAnimation>
            </div>
            <Modal
                open={openTuto}
                onClose={handleCloseTuto}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >

                <div style={{display : "flex", justifyContent : "center", height : "100%", alignItems : "center"}}>
                    <div
                        style={{
                            backgroundColor : "white",
                            width : "80%",
                            maxWidth : "1280px",
                            maxHeight : "90vh",
                            borderRadius : "25px",
                            padding : "20px",
                            position:"relative"
                        }}
                    >
                        <a style={{position : "relative", top : "-40px", left : "-35px", fontSize : "40px", color : "white"}} onClick={handleCloseTuto}>
                            <i className="fas fa-circle"/>
                            <i className="fas fa-times-circle" style={{position : "relative", left : "-45px", color : "red", fontSize : "50px"}}/>
                        </a>
                        <div style={{textAlign : "left", marginTop : "-40px", maxHeight : "calc(80vh + 20px)", overflowY : "auto"}}>
                            <div>
                                <Grid container style={{width : "90%", marginBottom : "20px", marginLeft : "5%"}}>
                                    <Grid xs={6}>
                                        <h2 style={{color : "#0F3F62", fontWeight : "bold"}}>Tutoriel</h2>
                                        <h3 style={{color : "#55BDBC"}}>Suivis personnalisés</h3>
                                    </Grid>
                                    <Grid
                                        xs={6}
                                        style={{display : "flex", justifyContent : "center", alignItems : "center", flexDirection : "column", cursor : "pointer"}}
                                        onClick={() => {window.open("https://www.youtube.com/watch?v=G_IveIyY49g&list=PLiyxvOGtiPXVpUGLu1tqTj0OwwhelripS&index=2", "_blank")}}
                                    >
                                        <PlayCircleOutlineRounded style={{fontSize : "50px"}}/>
                                        <h3 style={{color : "#0F3F62", fontWeight : "bold"}}>Voir la vidéo</h3>
                                    </Grid>
                                    <Grid xs={12}>
                                        <p style={{paddingTop : "30px"}}>
                                            Dans "Suivis personnalisés" vous retrouverez la liste de vos {nameEleve}s.
                                        </p>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6} lg={6} xl={6} style={{padding : "30px", display : "flex", alignItems : "center"}}>
                                        <p style={{textAlign : "center", margin : "0px"}}>
                                            <img src={nameEleve === "élève" ? imageTutoEnseignant : (nameEleve === "patient" ? imageTutoOrthophoniste : imageTutoFle)} style={{width : "90%", boxShadow : "0px 5px 8px 1px rgba(0, 0, 0, 0.2)", borderRadius : "25px"}}/>
                                        </p>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <p style={{paddingTop : "30px"}}>
                                            <span style={{fontWeight : "bold", fontSize : "115%"}}>Ajouter un {nameEleve}</span><br/><br/>
                                            Vous pouvez en ajouter en cliquant sur le bouton "Ajouter un {nameEleve}".<br/>
                                            Il vous suffira de rentrer son nom et son prénom dans les champs correspondants et cliquer sur "Ajouter"
                                        </p>
                                    </Grid>
                                    <Grid xs={12}>
                                        <hr/>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <p style={{paddingTop : "30px"}}>
                                            <span style={{fontWeight : "bold", fontSize : "115%"}}>Voir les résultats d'un {nameEleve}</span><br/><br/>
                                            Vous pouvez voir les résultats des activités d'un de vos {nameEleve} en cliquant sur le bouton "Résultats" à coté de son prénom.
                                        </p>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6} lg={6} xl={6} style={{padding : "30px", display : "flex", alignItems : "center"}}>
                                        <p style={{textAlign : "center", margin : "0px"}}>
                                            <img src={imageTuto2} style={{width : "90%", boxShadow : "0px 5px 8px 1px rgba(0, 0, 0, 0.2)", borderRadius : "25px"}}/>
                                        </p>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Fab color="primary" aria-label="add" style={{position : "fixed", bottom : "15px", right : "15px", cursor : "pointer"}} onClick={() => {
                setOpenTuto(true);
            }}>
                <span style={{fontSize : "200%"}}>?</span>
            </Fab>
        </div>
    )
}
