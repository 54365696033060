import React, { useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {MDBSpinner} from 'mdbreact';

import svgLogin from "../../assets/img/svg/login-svg.svg";
import GameCardMyCreations from "../../components/dashboard/GameCardMyCreations";
import firebaseConnector from "../../api/firebase-connector";

//IMAGES
import quizImage from '../../assets/img/games/quiz.jpg'
import soonImage from '../../assets/img/games/soon.jpg'
import imageTuto from '../../assets/img/tuto/MesCreations.gif'
import Button from "@material-ui/core/Button";
import {
    Checkbox, Fab,
    FormControl,
    Grid,
    Input,
    InputLabel,
    List, ListItem, ListItemIcon,
    ListItemText,
    MenuItem,
    Modal,
    Select
} from "@material-ui/core";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {DeleteRounded, EditRounded, PlayCircleOutlineRounded, SendRounded} from "@material-ui/icons";

export default function MyCreations(){

    let history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [openTuto, setOpenTuto] = useState(false);
    const [games, setGames] = useState([]);

    useEffect(()=>{
        const start = async function () {
            let userId = firebaseConnector.auth().currentUser.uid;
            firebaseConnector.firestore().collection("user_information").doc(userId).collection("games").orderBy("creationTime", "desc").onSnapshot(async () => {
                try {

                    let refTutos = firebaseConnector.firestore().collection("user_information").doc(userId).collection("tutorials").where("type", "==", "mes creations");
                    let queryTutos = await refTutos.get();
                    if(queryTutos.empty){
                        setOpenTuto(true);
                    }

                    let refGames = firebaseConnector.firestore().collection("user_information").doc(userId).collection("games").orderBy("creationTime", "desc");
                    let gamesCollection = await refGames.get();
                    let gamesTab = [];
                    for (let game of gamesCollection.docs) {
                        if (game.data().suppressed) {
                            continue;
                        } else {
                            let typeImage;
                            switch (game.data().type) {
                                case "quiz":
                                    typeImage = quizImage
                                    break;
                                default :
                                    typeImage = soonImage;
                                    break;
                            }

                            gamesTab.push({
                                id: game.id,
                                image: typeImage
                            });
                        }
                    }

                    setGames([...gamesTab])
                    setIsLoading(false)
                }catch(e){

                }
            });
        }
        start();
    }, [])

    useEffect(() => {

    }, [games])

    const handleCloseTuto = async () => {
        let userId = firebaseConnector.auth().currentUser.uid;
        await firebaseConnector.firestore()
            .collection("user_information").doc(userId)
            .collection("tutorials").add({
                type : "mes creations"
            });
        setOpenTuto(false);
    }

    if(isLoading)
        return (
            <div className="mt-5">
                <MDBSpinner/>
            </div>
        )

    return(
        <>
            <div style={{display : "flex", flexWrap : "wrap", justifyContent : (games.length === 1 ? "left" : "center")}}>
                {   games.length > 0 ?
                    games.map((game,index) => (
                        <GameCardMyCreations key={index} id={game.id} height="220px" width="30%" picture={game.image}/>
                    )) : (
                        <>
                            <p style={{textAlign : "center"}}>Vous n'avez pas encore créé de jeu !<br/><Button style={{minWidth : "150px", margin : "15px", borderRadius : "25px", backgroundColor : "#55BDBC", color : "white"}} variant="contained" color="primary" size="large" onClick={() => {history.push("/create")}}>
                                Créer un jeu
                            </Button></p>

                        </>
                    )
                }
            </div>
            <Modal
                open={openTuto}
                onClose={handleCloseTuto}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >

                <div style={{display : "flex", justifyContent : "center", height : "100%", alignItems : "center"}}>
                    <div
                        style={{
                            backgroundColor : "white",
                            width : "80%",
                            maxWidth : "1280px",
                            maxHeight : "90vh",
                            borderRadius : "25px",
                            padding : "20px",
                            position:"relative"
                        }}
                    >
                        <a style={{position : "relative", top : "-40px", left : "-35px", fontSize : "40px", color : "white"}} onClick={handleCloseTuto}>
                            <i className="fas fa-circle"/>
                            <i className="fas fa-times-circle" style={{position : "relative", left : "-45px", color : "red", fontSize : "50px"}}/>
                        </a>
                        <div style={{textAlign : "left", marginTop : "-40px", maxHeight : "calc(80vh + 20px)", overflowY : "auto"}}>
                            <div>
                                <Grid container style={{width : "90%", marginBottom : "20px", marginLeft : "5%"}}>
                                    <Grid item xs={6}>
                                        <h2 style={{color : "#0F3F62", fontWeight : "bold"}}>Tutoriel</h2>
                                        <h3 style={{color : "#55BDBC"}}>Mes créations</h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        style={{display : "flex", justifyContent : "center", alignItems : "center", flexDirection : "column", cursor : "pointer"}}
                                        onClick={() => {window.open("https://www.youtube.com/watch?v=jcyTmxAJ5Ec&list=PLiyxvOGtiPXVpUGLu1tqTj0OwwhelripS&index=5", "_blank")}}
                                    >
                                        <PlayCircleOutlineRounded style={{fontSize : "50px"}}/>
                                        <h3 style={{color : "#0F3F62", fontWeight : "bold"}}>Voir la vidéo</h3>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <p>Dans "Mes créations" vous retrouverez les activités que vous avez créés sous la forme de carte.</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{padding : "30px", display : "flex", alignItems : "center"}}>
                                        <p style={{textAlign : "center"}}>
                                            <img src={imageTuto} style={{width : "90%", boxShadow : "0px 5px 8px 1px rgba(0, 0, 0, 0.2)", borderRadius : "25px"}}/>
                                        </p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <p>
                                            Sur chaque carte, vous trouverez 4 boutons :
                                            <ul>
                                                <li>
                                                    <span style={{fontWeight : "bold"}}><PlayCircleOutlineRounded/> Jouer</span><br/>
                                                    Choisissez un.e de vos apprenant.te et jouer au jeu avec elle ou lui.<br/>
                                                </li>
                                                <li>
                                                    <span style={{fontWeight : "bold"}}><EditRounded/> Editer</span><br/>
                                                    Modifiez les questions de l'activité. Cette action changera les questions des activités que vous avez envoyé.<br/>
                                                </li>
                                                <li>
                                                    <span style={{fontWeight : "bold"}}><SendRounded/> Envoyer</span><br/>
                                                    Choisissez un.e de vos apprenant.te et génerer un lien à lui envoyer pour qu'il puisse jouer au jeu à distance.<br/>
                                                </li>
                                                <li>
                                                    <span style={{fontWeight : "bold", color : "red"}}><DeleteRounded/> Supprimer</span><br/>
                                                    Supprime l'activité. Vous ne pourrez pas la retrouver par la suite. Cette action est définitive.
                                                </li>
                                            </ul>
                                        </p>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Fab color="primary" aria-label="add" style={{position : "fixed", bottom : "15px", right : "15px", cursor : "pointer"}} onClick={() => {
                setOpenTuto(true);
            }}>
                <span style={{fontSize : "200%"}}>?</span>
            </Fab>
        </>
    )
}
