import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import {makeStyles} from "@material-ui/core/styles";
import metierConfig from "../../pages/metier.config.json";

import '../../assets/css/side-drawer.css'
import {
    Checkbox,
    FormControl,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Modal,
    Select
} from "@material-ui/core";
import {
    DeleteRounded,
    EditRounded,
    PlayCircleOutlineRounded, SendRounded
} from "@material-ui/icons";
import firebaseConnector from "../../api/firebase-connector";
import Button from "@material-ui/core/Button";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

export default function GameCardMyCreations({id, picture, width, height}){

    let history = useHistory();

    const [open, setOpen] = useState(false);
    const [openSuppress, setOpenSuppress] = useState(false);
    const [valid, setValid] = useState(false);
    const [type, setType] = useState("quiz");
    const [name, setName] = useState("");
    const [elevesTab, setElevesTab] = useState([]);
    const [multipleChoice, setMultipleChoice] = useState(true);
    const [link, setLink] = useState("");
    const [mode, setMode] = useState("play");
    const [personName, setPersonName] = useState([]);
    const [copyButtonText, setCopyButtonText] = useState("Copier le lien");
    const [nomEleve, setNomEleve] = useState("élève");

    const [isValidateModal, setIsValidateModal] = useState(false)

    useEffect(() => {
        const startFunction = async function () {
            let userId = firebaseConnector.auth().currentUser.uid;
            let userDoc = await firebaseConnector.firestore().collection("user_information").doc(userId).get();
            if(userDoc.exists){
                setNomEleve(metierConfig.nomEleve[userDoc.data().profession]);
            }

            let refGame = firebaseConnector.firestore().collection("user_information").doc(userId).collection("games").doc(id);
            const game = await refGame.get();
            if(game.exists){
                setType(game.data().type);
                setName(game.data().name);
                setValid(game.data().valid);

                let refEleves = firebaseConnector.firestore().collection("user_information").doc(userId).collection("eleves");
                const eleves = await refEleves.get();
                const newNames = [];
                for(let eleve of eleves.docs){
                    let myEleve = eleve.data();
                    myEleve.id = eleve.id;
                    newNames.push(myEleve);
                }
                setElevesTab([...newNames])
            }else{
                console.log("Nothing found");
            }
        }
        startFunction();
    }, [id]);

    const useStyles = makeStyles((theme) => ({
        tile : {
            width: "calc(" + width + " - 20px)",
            maxWidth : "350px",
            minWidth : "200px",
            minHeight : "200px",
            maxHeight : "350px",
            height: height,
            boxShadow : "0px 5px 8px 1px rgba(0, 0, 0, 0.2)",
            borderRadius : "25px",
            margin : "2.5%"
        },
        background : {
            height : "60%",
            backgroundImage : "url(" + picture + ")",
            backgroundSize : "cover",
            backgroundPosition : "center",
            backgroundRepeat : "no-repeat",
            overflow : "hidden",
            borderRadius : "25px 25px 0px 0px",
        },
        title: {
            color : "white",
            fontWeight : "bold",
            fontSize : "1.75rem",
            paddingTop : "5px",
            overflow : "hidden",
            whiteSpace : "nowrap",
            animation: `marqueelike 3000ms linear infinite`
        },
        unavailable : {
            height : "100%",
            backgroundColor : "rgba(0,0,0,0.5)",
            borderRadius : "25px",
            display : "flex",
            justifyContent : "center",
            alignItems : "center"
        },
        state : {
            color : "white",
            fontSize : "3rem",
            fontWeight : 600
        },
        banner : {
            backgroundColor : "rgba(255,255,255,1)",
            width : "100%",
            height : "40%",
            maxHeight : "150px",
            borderRadius : "0px 0px 25px 25px",
            textAlign : "center",
            fontWeight : "bold"
        },
        formControl: {
            margin: "15px",
            minWidth: 120,
            maxWidth: 300,
        },
        "@keyframes marqueelike": {
            "0%": {
                fontSize : "500%"
            },
            "99.99%": {
                fontSize : "100%"
            },
            "100%": {
                fontSize : "500%"
            }
        }
    }))
    const classes = useStyles();

    const handleOpen = () => {
        setPersonName([]);
        setIsValidateModal(false);
        setLink("");
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSuppress = () => {
        setOpenSuppress(false);
    };

    const handleEdit = () => {
        history.push("/create/" + type + "/" + id);
    }

    const handlePlay = () => {
        if(!valid){
            return;
        }
        setMultipleChoice(false);
        setMode("play");
        handleOpen();
    }

    const handleSend = () => {
        if(!valid){
            return;
        }
        setMultipleChoice(false);
        setMode("send");
        handleOpen();
    }

    const handleSuppress = () => {
        setOpenSuppress(true);
    }

    const handleChange = (event) => {
        if(Array.isArray(event.target.value)){
            setPersonName(event.target.value);
        }else{
            setPersonName([event.target.value]);
        }
        setIsValidateModal(true);
    };

    const generateLink = (eleveId, local = false) => {
        let userId = firebaseConnector.auth().currentUser.uid;
        let myLink = local ? "" : "https://app.pupylle.fr";
        myLink = myLink + "/play/" + userId + "+" + id + "+" + eleveId;
        return myLink;
    }

    function handlePlayModal() {
        let myEleve = personName[0];
        let playLink = generateLink(myEleve.id, true);
        history.push(playLink);
    }

    function handleSendModal() {
        let myEleve = personName[0];
        let playLink = generateLink(myEleve.id);
        setLink(playLink);
    }

    function handleCopyLink() {
        setCopyButtonText("Copié !");
        setTimeout(() => {
            setCopyButtonText("Copier le lien");
        }, 1500);
    }

    async function suppressActivity() {
        let userId = firebaseConnector.auth().currentUser.uid;
        await firebaseConnector.firestore().collection("user_information").doc(userId).collection("games").doc(id).set({
            suppressed : true
        }, {merge : true});
    }

    return(
        <>
            <div className={classes.tile}>
                <div className={classes.background}>
                    <h5 className={classes.title} style={{margin : "10px"}}>{(type[0].toUpperCase() + type.substring(1))}</h5>
                </div>
                <div className={classes.banner}>
                    <ScrollingText text={name}/>
                    <div style={{display : "inline-flex", width : "80%", justifyContent : "space-evenly"}}>
                        <div><PlayCircleOutlineRounded onClick={handlePlay} style={valid ? {cursor : "pointer"} : {color : "grey"}}/><p style={{fontSize : "50%", color : (valid ? "#0F3F62" : "grey")}}>Jouer</p></div>
                        <div><EditRounded onClick={handleEdit} style={{cursor : "pointer"}}/><p style={{fontSize : "50%", color : "#0F3F62"}}>Editer</p></div>
                        <div><SendRounded onClick={handleSend} style={valid ? {cursor : "pointer"} : {color : "grey"}}/><p style={{fontSize : "50%", color : (valid ? "#0F3F62" : "grey")}}>Envoyer</p></div>
                        <div><DeleteRounded onClick={handleSuppress} style={{cursor : "pointer", color : "red"}}/><p style={{fontSize : "50%", color : "red"}}>Suppr</p></div>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >

                <div style={{display : "flex", justifyContent : "center", height : "100%", alignItems : "center"}}>
                    <div
                        style={{
                            backgroundColor : "white",
                            width : "40%",
                            borderRadius : "25px",
                            padding : "20px"
                        }}
                    >
                        <a style={{position : "relative", top : "-40px", left : "-35px", fontSize : "40px", color : "white"}} onClick={handleClose}>
                            <i className="fas fa-circle"/>
                            <i className="fas fa-times-circle" style={{position : "relative", left : "-45px", color : "red", fontSize : "50px"}}/>
                        </a>
                        <div style={{textAlign : "center"}}>
                            <h2 style={{fontWeight : "bold", color : "#0F3F62"}}>{mode === "play" ? "Jouer à l'activité " + name + " avec : " : "Envoyer l'activité " + name + " à : "}</h2>
                            {elevesTab.length > 0 ? (
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-mutiple-checkbox-label">Tag</InputLabel>
                                    <Select
                                        id="eleveSelect"
                                        multiple={multipleChoice}
                                        value={personName.map(person => (person.prenom + " " + person.nom))}
                                        onChange={handleChange}
                                        input={<Input />}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {elevesTab.map((eleve, index) => (
                                            <MenuItem key={index} value={eleve} name={eleve.prenom + " " + eleve.nom}>
                                                <Checkbox checked={personName.indexOf(eleve) > -1} />
                                                <ListItemText primary={eleve.prenom + " " + eleve.nom} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                ) : (<p>Vous n'avez pas encore {"aeiouy".indexOf(nomEleve[0]) === -1 ? "de " : "d'"}{nomEleve}. Vous pouvez ajouter vos {nomEleve}s dans le menu "Suivis personnalisés".</p>)}
                        </div>
                        <div style={{textAlign : "center", width : "100%", paddingBottom : "50px"}}>
                            {
                                link !== "" ? (
                                    <div style={{width : "100%", display : "flex", justifyContent : "center"}}>
                                        <p style={{width : "75%", overflowWrap: "break-word", textAlign : "center"}}>
                                            {link}
                                        </p>
                                    </div>) : (<></>)}
                            <Button variant="contained" color="primary" disabled={!isValidateModal} style={{borderRadius : "25px", backgroundColor : (!isValidateModal ? '#0000001e' : "#55bdbc"), color : (!isValidateModal ? 'rgba(0,0,0,0.118)' : "white")}} size="large" onClick={() => {
                                if(mode === "play"){
                                    handlePlayModal();
                                }else{
                                    if(link === ""){
                                        handleSendModal();
                                    }
                                }
                            }}>
                                {
                                    mode === "play" ? "Jouer" : (
                                        link === "" ? "Genérer le lien" :
                                            (<CopyToClipboard onCopy={handleCopyLink} text={link}>
                                                <span>{copyButtonText}</span>
                                            </CopyToClipboard>)
                                    )
                                }
                            </Button>
                        </div>

                    </div>
                </div>
            </Modal>
            <Modal
                open={openSuppress}
                onClose={handleCloseSuppress}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >

                <div style={{display : "flex", justifyContent : "center", height : "100%", alignItems : "center"}}>
                    <div
                        style={{
                            backgroundColor : "white",
                            width : "40%",
                            borderRadius : "25px",
                            padding : "20px"
                        }}
                    >
                        <a style={{position : "relative", top : "-40px", left : "-35px", fontSize : "40px", color : "white"}} onClick={handleCloseSuppress}>
                            <i className="fas fa-circle"/>
                            <i className="fas fa-times-circle" style={{position : "relative", left : "-45px", color : "red", fontSize : "50px"}}/>
                        </a>
                        <div style={{textAlign : "center"}}>
                            <h2 style={{fontWeight : "bold", color : "red"}}>Attention !</h2>
                            <p>Vous allez supprimer l'activité et vous ne pourrez pas annuler cette action.</p>
                        </div>
                        <div style={{textAlign : "center", width : "100%", paddingBottom : "50px"}}>
                            <Button style={{minWidth : "150px", margin : "15px", borderRadius : "25px", backgroundColor : "#55bdbc", color : "white"}} variant="contained" color="primary" size="large" onClick={handleCloseSuppress}>
                                Non
                            </Button>
                            <Button style={{minWidth : "150px", margin : "15px", borderRadius : "25px", backgroundColor : "red", color : "white"}} variant="contained" color="primary" size="large" onClick={() => {suppressActivity(); handleCloseSuppress()}}>
                                Oui
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}


function ScrollingText({text}){

    const [h5Element, setH5Element] = useState(React.createRef());
    const [divElement, setDivElement] = useState(React.createRef());
    const [scrolling, setScrolling] = useState(false);
    const [reload, setReload] = useState(false);
    const [fontSize, setFontSize] = useState(28);

    const useStyles = makeStyles((theme) => ({
        title: {
            fontWeight : "bold",
            fontSize : fontSize + "px",
            paddingTop : "5px",
            overflow : "hidden"
        }
    }))
    const classes = useStyles();

    useEffect(() => {
        try {
            if (divElement.clientHeight * 1.1 < h5Element.clientHeight || divElement.clientWidth * 1.1 < h5Element.clientWidth) {
                //return "marquee"
                setScrolling(true)
                setFontSize(fontSize - 3);
            } else {
                //return ""
                //setScrolling(false)
            }
        }catch (e) {

        }
    }, [h5Element, divElement, text, fontSize, reload])

    const handleResize = () => {
        //setReload(!reload)
    }

    window.addEventListener('resize', handleResize)

    return (
        <div style={{height: "50%", overflow : "hidden"}} ref={(el) => {setDivElement(el)}}>
            <p className={classes.title} style={{scrolling : (scrolling ? "nowrap" : "normal")}} ref={(el) => {setH5Element(el)}}>
                {text}
            </p>
        </div>
    )
}
