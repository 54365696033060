import React, {useEffect, useState} from 'react';
import {Route, Switch, useLocation, useHistory} from 'react-router-dom';
import firebaseConnector from "./api/firebase-connector";

import RoutesWithNavigationApp from "./routes-navigation/RoutesWithNavigationApp";
import LogInPage from "./pages/LogInPage";
import SignUpPage from "./pages/SignUpPage";

import './assets/css/app.css';
import QuizCreation from "./section/create/QuizCreation";
import Play from "./section/play/Play";

export default function App() {
    const [isLoading, setIsLoading] = useState(true)
    const [isConnected, setIsConnected] = useState(true)
    const {pathname} = useLocation();

    let history = useHistory();

    useEffect(()=>{
        firebaseConnector.auth().onAuthStateChanged((user)=>{
            if (user) {
                setIsConnected(true);
            } else {
                if(pathname === "/"){
                    history.push("/login")
                }
                setIsConnected(false);
            }
            setIsLoading(false);
        })

    }, [history, pathname])

    if(isLoading)
        return(
            <div style={{display : "flex", width : "100%", height : "100vh", justifyContent : "center", alignItems : "center"}}>
                <div className="spinner-grow" style={{color : "#55BDBC"}} role="status">
                    <span className="sr-only">Chargement en cours...</span>
                </div>
            </div>
        )

    if (isConnected) {
        return (
            <Switch>
                <Route path='/create/quiz/:id' exact component={QuizCreation}/>
                <Route path='/play/:id' exact component={Play}/>
                <Route path='/' component={RoutesWithNavigationApp}/>
            </Switch>
        );
    }else{
        return (
            <Switch>
                <Route path='/login' exact component={LogInPage}/>
                <Route path='/signup' exact component={SignUpPage}/>
                <Route path='/play/:id' exact component={Play}/>
            </Switch>
        );
    }
}
