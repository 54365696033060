import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles'
import ToastContext from "./context/ToastContext";
import Swal from 'sweetalert2';

import '@fortawesome/fontawesome-pro/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css'

import './index.css';

import App from './App';

const theme = createMuiTheme({
    palette: {
        primary: {
            contrastText: "#55BDBC",
            main: "#FFFFFF",
            light: "#f7fafc"
        },
    },
})
const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toastElement) => {
        toastElement.addEventListener('mouseenter', Swal.stopTimer)
        toastElement.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <ToastContext.Provider value={{toast: toast}}>
                <App/>
            </ToastContext.Provider>
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
