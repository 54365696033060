import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import '../../assets/css/side-drawer.css'
import {Modal} from "@material-ui/core";

export default function GameCardCreation({name, picture, unavailable, width, height, children}){

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const useStyles = makeStyles((theme) => ({
        tile : {
            width: width,
            height: height,
            backgroundImage : "url(" + picture + ")",
            borderRadius : "25px",
            backgroundSize : "cover",
            backgroundPosition : "center",
            backgroundRepeat : "no-repeat",
            margin : "2.5%",
            boxShadow : "0px 5px 8px 1px rgba(0, 0, 0, 0.2)",
            overflow : "hidden",
            cursor : "pointer",
            display : "flex",
            justifyContent : "center",
            alignItems : "center",
            transition: "box-shadow 250ms ease-out 100ms, margin 250ms ease-out 100ms",
            "&:hover, &:focus" : {
                margin : "2.25% 2.25% 2.75% 2.25%",
                boxShadow : "0px 8px 18px 1px rgba(0, 0, 0, 0.4)"
            }
        },
        tileUnavailable : {
            width: width,
            height: height,
            backgroundImage : "url(" + picture + ")",
            boxShadow : "0px 5px 8px 1px rgba(0, 0, 0, 0.2)",
            borderRadius : "25px",
            backgroundSize : "cover",
            backgroundPosition : "center",
            backgroundRepeat : "no-repeat",
            margin : "2.5%",
            overflow : "hidden"
        },
        title: {
            position : "absolute",
            padding : "15px",
            fontWeight : "bold",
            fontSize : "10vh",
            textShadow : "0px 0px 75px black",
            color : "white"
        },
        unavailable : {
            height : "100%",
            backgroundColor : "rgba(0,0,0,0.75)",
            borderRadius : "25px",
            display : "flex",
            justifyContent : "center",
            alignItems : "center"
        },
        state : {
            color : "white",
            fontSize : "3rem",
            fontWeight : 600
        }
    }))
    const classes = useStyles();

    return(
        <>
            <div className={unavailable ? classes.tileUnavailable : classes.tile} onClick={unavailable ? undefined : handleOpen}>
                <p className={classes.title}>{name}</p>
                {
                    unavailable ? (
                        <div className={classes.unavailable}>
                            <p className={classes.state}>Bientôt disponible</p>
                        </div>
                    ) : (<></>)
                }
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >

                <div style={{display : "flex", justifyContent : "center", height : "100%", alignItems : "center"}}>
                    <div
                        style={{
                            backgroundColor : "white",
                            width : "75%",
                            borderRadius : "25px",
                            padding : "20px"
                        }}
                    >
                        <a style={{position : "relative", top : "-40px", left : "-35px", fontSize : "40px", color : "white"}} onClick={handleClose}>
                            <i className="fas fa-circle"/>
                            <i className="fas fa-times-circle" style={{position : "relative", left : "-45px", color : "red", fontSize : "50px"}}/>
                        </a>
                        {children}
                    </div>
                </div>
            </Modal>
        </>
    )
}
