import React, {useEffect, useState} from 'react';
import {
    MDBIcon,
    MDBInput,
    MDBSpinner,
} from 'mdbreact';

import '../../assets/css/dashboard.css';
import Button from "@material-ui/core/Button";
import GameCardCreation from "../../components/dashboard/GameCardCreation";
import {useHistory} from "react-router-dom";
import firebaseConnector from "../../api/firebase-connector";

//IMAGES
import quizImage from '../../assets/img/games/quiz.jpg'
import soonImage from '../../assets/img/games/soon.jpg'
import {Fab, Grid, Modal} from "@material-ui/core";
import {DeleteRounded, EditRounded, PlayCircleOutlineRounded, SendRounded} from "@material-ui/icons";
import imageTuto from "../../assets/img/tuto/CreerUnJeu-1.png";
import imageTuto2 from "../../assets/img/tuto/CreerUnJeu-2.png";

export default function Create(){
    let history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [openTuto, setOpenTuto] = useState(false);

    const [quizName, setQuizName] = useState("");
    const [isValidateQuiz, setIsValidateQuiz] = useState(false)

    const [isPending, setIsPending] = useState(false)

    const handleQuiz = async () => {
        setIsPending(true);

        let user = firebaseConnector.auth().currentUser;
        const res = await firebaseConnector.firestore().collection("user_information").doc(user.uid).collection("games").add({
            type : "quiz",
            name : quizName,
            creationTime : firebaseConnector.firestore.Timestamp.fromDate(new Date()),
            valid : false
        });

        history.push("/create/quiz/" + res.id);
    }

    const handleChangeQuizName = (value)=>{
        setQuizName(value);
        if(value !== ""){
            setIsValidateQuiz(true)
        }
    }

    const handleCloseTuto = async () => {
        let userId = firebaseConnector.auth().currentUser.uid;
        await firebaseConnector.firestore()
            .collection("user_information").doc(userId)
            .collection("tutorials").add({
                type : "creer un jeu"
            });
        setOpenTuto(false);
    }

    useEffect(()=>{

        const prepare = async () => {
            let userId = firebaseConnector.auth().currentUser.uid;
            let refTutos = firebaseConnector.firestore().collection("user_information").doc(userId).collection("tutorials").where("type", "==", "creer un jeu");
            let queryTutos = await refTutos.get();
            if(queryTutos.empty){
                setOpenTuto(true);
            }
        }

        prepare();

        setIsLoading(false)
    }, [])

    if(isLoading)
        return (
            <div className="mt-5">
                <MDBSpinner/>
            </div>
        )

    return(
        <>
            <div style={{display : "flex", flexWrap : "wrap", justifyContent : "space-between"}}>
                <GameCardCreation name={"Quiz"} height="37vh" width="100%" picture={quizImage} handler={"quiz"}>
                    <h2>Nouveau quiz</h2>

                    <MDBInput
                        size="md"
                        value={quizName}
                        getValue={(value)=>{handleChangeQuizName(value)}}
                        label="Nom du quiz"
                        icon="gamepad"
                        iconLight={true}
                        style={{fontSize: "1.2rem"}}
                    />
                    <Button variant="contained" color="primary" disabled={!isValidateQuiz} style={{borderRadius : "25px", backgroundColor : (!isValidateQuiz ? '#0000001e' : "#55bdbc"), color : (!isValidateQuiz ? 'rgba(0,0,0,0.118)' : "white")}} size="large" onClick={handleQuiz}>
                        {isPending && <MDBIcon icon="spinner" pulse size="3x" fixed />}Créer
                    </Button>
                </GameCardCreation>
                <GameCardCreation unavailable name={""} height="37vh" width="45%" picture={soonImage} nextPath={"mot-croises"}/>
                <GameCardCreation unavailable name={""} height="37vh" width="45%" picture={soonImage} nextPath={"association"}/>
            </div>
            <Modal
                open={openTuto}
                onClose={handleCloseTuto}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >

                <div style={{display : "flex", justifyContent : "center", height : "100%", alignItems : "center"}}>
                    <div
                        style={{
                            backgroundColor : "white",
                            width : "80%",
                            maxWidth : "1280px",
                            maxHeight : "90vh",
                            borderRadius : "25px",
                            padding : "20px",
                            position:"relative"
                        }}
                    >
                        <a style={{position : "relative", top : "-40px", left : "-35px", fontSize : "40px", color : "white"}} onClick={handleCloseTuto}>
                            <i className="fas fa-circle"/>
                            <i className="fas fa-times-circle" style={{position : "relative", left : "-45px", color : "red", fontSize : "50px"}}/>
                        </a>
                        <div style={{textAlign : "left", marginTop : "-40px", maxHeight : "calc(80vh + 20px)", overflowY : "auto"}}>
                            <div>
                                <Grid container style={{width : "90%", marginBottom : "20px", marginLeft : "5%"}}>
                                    <Grid xs={6}>
                                        <h2 style={{color : "#0F3F62", fontWeight : "bold"}}>Tutoriel</h2>
                                        <h3 style={{color : "#55BDBC"}}>Créer un jeu</h3>
                                    </Grid>
                                    <Grid
                                        xs={6}
                                        style={{display : "flex", justifyContent : "center", alignItems : "center", flexDirection : "column", cursor : "pointer"}}
                                        onClick={() => {window.open("https://www.youtube.com/watch?v=fn5xefZ7G6k&list=PLiyxvOGtiPXVpUGLu1tqTj0OwwhelripS&index=3", "_blank")}}
                                    >
                                        <PlayCircleOutlineRounded style={{fontSize : "50px"}}/>
                                        <h3 style={{color : "#0F3F62", fontWeight : "bold"}}>Voir la vidéo</h3>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <p>Dans "Créer un jeu" vous retrouverez les activités que vous pouvez créer.</p>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6} lg={6} xl={6} style={{padding : "30px", display : "flex", alignItems : "center"}}>
                                        <p style={{textAlign : "center"}}>
                                            <img src={imageTuto} style={{width : "90%", boxShadow : "0px 5px 8px 1px rgba(0, 0, 0, 0.2)", borderRadius : "25px"}}/>
                                        </p>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <p style={{paddingTop : "30px"}}>
                                            <span style={{fontWeight : "bold", fontSize : "115%"}}>Exemple</span><br/>
                                            Pour créer un quiz, il vous suffit de cliquer sur l'activité "Quiz" pour que s'ouvre une fenêtre dans laquelle vous devez rentrer le nom de votre quiz.<br/><br/>
                                            Une fois que vous avez écris le nom du quiz cliquez sur le bouton "Créer" pour créer le quiz.
                                        </p>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} style={{padding : "30px", display : "flex", alignItems : "center"}}>
                                        <p style={{textAlign : "center"}}>
                                            <img src={imageTuto2} style={{width : "90%", boxShadow : "0px 5px 8px 1px rgba(0, 0, 0, 0.2)", borderRadius : "25px"}}/>
                                        </p>
                                    </Grid>
                                </Grid>




                                <p style={{paddingTop : "35px"}}>
                                    Une fois que vous avez écrit le nom du quiz cliquez sur le bouton "Créer" pour créer le quiz.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Fab color="primary" aria-label="add" style={{position : "fixed", bottom : "15px", right : "15px", cursor : "pointer"}} onClick={() => {
                setOpenTuto(true);
            }}>
                <span style={{fontSize : "200%"}}>?</span>
            </Fab>
        </>
    )
}
