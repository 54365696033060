import React, {useEffect, useState} from 'react'
import {MDBCol, MDBInput, MDBRow} from "mdbreact";
import {useHistory} from "react-router-dom";
import {Button} from "@material-ui/core";

import svgLogin from '../assets/img/svg/login-svg.svg'
import firebaseConnector from "../api/firebase-connector";
import '../assets/css/sign.css'

export default function LogInPage(){
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const [isValidate, setIsValidate] = useState(false)

    let history = useHistory();

    useEffect(()=>{
        setIsValidate(email!=="" && password!=="")
    }, [email, password])

    useEffect(()=>{
        let user = firebaseConnector.auth().currentUser;
        if(user){
            history.push("/");
        }
    }, [history])

    const handleSubmit = async (event) =>{
        event.preventDefault()
        firebaseConnector.auth().signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in
                history.push("/");
            })
            .catch(error => {
                setError(error)
            });

    }

    return(
        <div>
            <MDBRow style={{marginLeft: "0px", marginRight: "0px", height: "100vh", justifyContent: "center", backgroundColor : "#55BDBC", color : "white"}}>
                <MDBCol md="1"/>
                <MDBCol md="4" className="container" style={{alignItems: "center", display: "grid"}}>
                    <MDBRow>
                        <MDBCol size="10">
                            <form onSubmit={handleSubmit}>
                                <div className="signin">
                                    <h2 style={{textAlign: "center", fontWeight: "bold", color : "white"}}>Connexion</h2>
                                    <h6 style={{textAlign: "center", color : "white"}}>Version bêta gratuite</h6>
                                    <br/>

                                    <MDBInput
                                        focused
                                        size="lg"
                                        label="Adresse e-mail"
                                        getValue={(value)=>{setEmail(value)}}
                                        icon="envelope"
                                        value={email}
                                        iconLight={true}
                                        type="email"
                                        style={{fontSize: "1.2rem"}}
                                    />

                                    <MDBInput
                                        size="lg"
                                        value={password}
                                        getValue={(value)=>{setPassword(value)}}
                                        label="Mot de passe"
                                        icon="lock"
                                        iconLight={true}
                                        type="password"
                                        style={{fontSize: "1.2rem"}}
                                    />

                                    <div className="text-center" style={{marginTop: "2rem"}}>
                                        <Button variant="contained" type="submit" color="primary" disabled={!isValidate} style={{borderRadius : "25px", backgroundColor : (!isValidate ? '#0000001e' : "white"), color : (!isValidate ? 'rgba(0,0,0,0.118)' : "#55bdbc")}} size="large">
                                            Connexion
                                        </Button>
                                        {error && <p>{error.message}</p>}
                                    </div>
                                    <p style={{textAlign: "center", marginTop : "15px"}}>
                                        Vous n'avez pas de compte ? <br/><a href="/signup" style={{color : "white",fontWeight : "bold",}}>Créez-en un</a>
                                    </p>
                                </div>
                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol md="6" style={{backgroundColor: "#FFFFFF", textAlign: "center", display: "flex", flexDirection : "column", justifyContent : "center"}}>
                    <p style={{color : "#55BDBC", fontWeight : "bold", width : "100%", textAlign : "center", fontSize : "50px"}}>Pupylle</p>
                    <img src={svgLogin} alt="login-svg" className="img-fluid"/>
                </MDBCol>
            </MDBRow>
        </div>
    );
}