import React, {useEffect, useState} from 'react';

import '../../assets/css/dashboard.css'
import {useParams} from "react-router-dom";
import firebaseConnector from "../../api/firebase-connector";
import PlayQuiz from "./PlayQuiz";

export default function Play(){

    const [type, setType] = useState("");
    const [userId, setUserId] = useState("");
    const [gameId, setGameId] = useState("");
    const [eleveId, setEleveId] = useState("");

    let { id } = useParams();

    const prepare = async () => {
        let params = id.split("+");
        setUserId(params[0]);
        setGameId(params[1]);
        setEleveId(params[2]);
        let refGame = firebaseConnector.firestore().collection("user_information").doc(params[0]).collection("games").doc(params[1]);
        let docGame = await refGame.get();
        if(docGame.exists){
            setType(docGame.data().type);
        }
    }

    useEffect(() => {
        prepare();
    }, [])

    switch(type) {
        case "quiz" :
            return (
                <PlayQuiz userId={userId} gameId={gameId} eleveId={eleveId}/>
            )
        default :
            return (
                <></>
            )
    }
}