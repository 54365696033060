import React, {useEffect, useState} from 'react';

import '../../assets/css/dashboard.css'
import {
    Button,
    Grid,
    Modal
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import firebaseConnector from "../../api/firebase-connector";

export default function PlayQuiz({userId, gameId, eleveId}){
    let history = useHistory();

    const [open, setOpen] = useState(true);

    const [isConnected, setIsConnected] = useState(false);
    const [image, setImage] = useState(false);
    const [numQuestion, setNumQuestion] = useState(1);
    const [quizName, setQuizName] = useState("");
    const [questions, setQuestions] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState(0);

    const [modalTitle, setModalTitle] = useState("Comment jouer ?");
    const [modalText, setModalText] = useState("Tu vas jouer à un quiz.\nLis bien la question et cliques sur la bonne réponse 😃");
    const [modalButtonText, setModalButtonText] = useState("Jouer");
    const [modalButtonHandleClose, setModalButtonHandleClose] = useState(false);

    const [idGameEvent, setIdGameEvent] = useState("");
    const [nbErreur, setNbErreur] = useState(0);

    const useStyles = makeStyles((theme) => ({
        QuestionSide : {
            backgroundColor : "#55BDBC",
            color : "white",
            textAlign : "center",
            '& h2' : {
                width : "90%",
                marginLeft : "5%",
                paddingTop : "5%",
                color : "white",
                fontWeight : "bold"
            },
            '& h6' : {
                color : "white",
                fontWeight : "bold"
            }
        },
        ResponsesSide : {
            textAlign : "center",
            backgroundColor : "white",
        },
        logoText : {
            padding : "30px 0px 0px 30px",
            fontWeight : "bold",
            textAlign : "left",
            fontSize : "25px"
        },
        AnswerButtonsContainer : {
            display : "flex",
            flexDirection : "column",
            alignItems : "center",
            justifyContent : "center"
        },
        AnswerButton : {
            cursor : "pointer",
            backgroundColor : "#55BDBC",
            color : "white",
            fontWeight : "bold",
            width : "50%",
            margin : "25px",
            textAlign : "center",
            padding : "15px",
            fontSize : "30px",
            borderRadius : "15px"
        },
        SelectedAnswerButton : {
            cursor : "pointer",
            backgroundColor : "#B620E0",
            color : "white",
            fontWeight : "bold",
            width : "50%",
            margin : "25px",
            textAlign : "center",
            padding : "15px",
            fontSize : "30px",
            borderRadius : "15px"
        },
        QuestionImage : {
            maxWidth : "90%",
            maxHeight : "50vh",
            margin : "50px 20px 20px 20px"
        },
        modalDiv : {
            [theme.breakpoints.up('xs')] : {
                fontSize : "2vh",
                "& h2" : {
                    fontSize : "3vh"
                }
            },
            [theme.breakpoints.up('sm')] : {
                fontSize : "2.3vh",
                "& h2" : {
                    fontSize : "4vh"
                }
            },
            [theme.breakpoints.up('md')] : {
                fontSize : "2.6vh",
                "& h2" : {
                    fontSize : "5vh"
                }
            },
            [theme.breakpoints.up('lg')] : {
                fontSize : "3vh",
                "& h2" : {
                    fontSize : "6vh"
                }
            }
        }
    }))
    const classes = useStyles();

    useEffect(() => {
        const onUnload = async (e, docId) => {
            const sendEvent = async () => {
                await firebaseConnector.firestore().collection("user_information").doc(userId).collection("eleves").doc(eleveId).collection("games").doc(docId).collection("events").add({
                    event : "quit",
                    time : firebaseConnector.firestore.Timestamp.fromDate(new Date())
                });
            }
            try{
                e.preventDefault();
                sendEvent();
            }catch (e){

            }
        };

        const prepare = async () => {
            let user = firebaseConnector.auth().currentUser;
            if(user){
                setIsConnected(true);
            }else{
                setIsConnected(false);
            }

            let refQuiz = firebaseConnector.firestore().collection("user_information").doc(userId).collection("games").doc(gameId);
            let quiz = await refQuiz.get();
            if(quiz.exists){
                setQuizName(quiz.data().name);
            }else{
                history.push("/");
            }
            let refQuestions = refQuiz.collection("questions");
            let questionsQuery = await refQuestions.get();
            if(!questionsQuery.empty){
                let questionsTab = [];
                for(let question of questionsQuery.docs){
                    questionsTab.push(question.data())
                }
                setQuestions([...questionsTab])
                if(idGameEvent === ""){
                    var newDoc = await firebaseConnector.firestore().collection("user_information").doc(userId).collection("eleves").doc(eleveId).collection("games").add({
                        type :"quiz",
                        name : quiz.data().name,
                        playTime : firebaseConnector.firestore.Timestamp.fromDate(new Date()),
                        nbErreur : 0,
                        success : false
                    })
                    await firebaseConnector.firestore().collection("user_information").doc(userId).collection("eleves").doc(eleveId).collection("games").doc(newDoc.id).collection("events").add({
                        event : "start",
                        time : firebaseConnector.firestore.Timestamp.fromDate(new Date())
                    });
                    setIdGameEvent(newDoc.id);
                    window.addEventListener("beforeunload", (e) => onUnload(e, newDoc.id));
                }
            }else{
                history.push("/");
            }
        }

        prepare();
    }, [eleveId, gameId, history, idGameEvent, userId])

    useEffect(() => {

    }, [image])

    const handleEndOfQuiz = () => {
        if(isConnected){
            history.push("/");
        }else{
            window.location.href = "https://app.pupylle.fr";
        }
    }

    const handleClose = () => {
        if(modalButtonText !== "") {
            setOpen(false);
        }
    };

    useEffect(() => {

        const loadImage = async () => {
            if(questions.length === 0){
                return;
            }
            setImage(await getImgUrl(questions[numQuestion - 1].imageFirebasePath));
        }

        loadImage();
    }, [numQuestion, questions])

    const handleClickAnswer = (value) => {
        setSelectedAnswer(value)
    }

    const handleValider = async () => {

        let event;

        if(selectedAnswer === questions[numQuestion - 1].goodAnswer){

            if(numQuestion === questions.length){
                event = "success";

                await firebaseConnector.firestore().collection("user_information").doc(userId).collection("eleves").doc(eleveId).collection("games").doc(idGameEvent).set({
                    success : true
                }, {merge : true});

                setModalTitle("Félicitations ! Tu as réussi toutes les questions ! 👏");
                if(isConnected){
                    setModalButtonText("Quitter");
                    setModalText("");
                }else{
                    setModalButtonText("");
                    setModalText("Vous pouvez fermer la page 😁");
                }
                setModalButtonHandleClose(true);

                setOpen(true);
            }else {
                event = "good";
                setModalTitle("Félicitations ! Continue comme ça ! 😁");
                setModalText("");
                setModalButtonText("Question suivante");
                setOpen(true);

                setNumQuestion(numQuestion + 1);
                setSelectedAnswer(0);

                if (questions.length === 0) {
                    return;
                }
                setImage(await getImgUrl(questions[numQuestion].imageFirebasePath));
            }
        }else{
            event = "wrong";
            await firebaseConnector.firestore().collection("user_information").doc(userId).collection("eleves").doc(eleveId).collection("games").doc(idGameEvent).set({
                nbErreur : (nbErreur + 1)
            }, {merge : true});
            setNbErreur(nbErreur + 1);
            setModalTitle("Ce n'est pas la bonne réponse, mais tu peux réessayer ! 😉");
            setModalText(questions[numQuestion - 1].indice !== "" ? "Indice : " + questions[numQuestion - 1].indice : "");
            setModalButtonText("Réessayer");
            setOpen(true);
            setSelectedAnswer(0);
        }

        if(idGameEvent !== ""){
            let addEndEvent = false;
            if(event === "success"){
                addEndEvent = true;
                event = "good";
            }

            let part1 = questions[numQuestion - 1].intitule;
            let part2 = eval('questions[numQuestion - 1].reponse' + selectedAnswer);
            let part3 = eval('questions[numQuestion - 1].reponse' + questions[numQuestion - 1].goodAnswer);

            await firebaseConnector.firestore().collection("user_information").doc(userId).collection("eleves").doc(eleveId).collection("games").doc(idGameEvent).collection("events").add({
                event : event,
                numQuestion : numQuestion,
                part1 : part1,
                part2 : part2,
                part3 : part3,
                time : firebaseConnector.firestore.Timestamp.fromDate(new Date())
            });
            if(addEndEvent){
                await firebaseConnector.firestore().collection("user_information").doc(userId).collection("eleves").doc(eleveId).collection("games").doc(idGameEvent).collection("events").add({
                    event : "success",
                    time : firebaseConnector.firestore.Timestamp.fromDate(new Date())
                });
            }
        }
    }

    const getImgUrl = async (firestorePath) => {
        if(firestorePath === ""){
            return "";
        }
        let refStorage = firebaseConnector.storage().ref();
        let refImage = refStorage.child(firestorePath);
        let link = await refImage.getDownloadURL();
        return link;
    }

    return (
        <>
            <Grid container style={{height : "100vh"}}>
                <Grid item xs={12} sm={12} md={6} className={classes.QuestionSide}>
                    <Grid container>
                        <Grid item xs={12}>
                            <p className={classes.logoText}>Pupylle</p>
                        </Grid>
                        <Grid item xs={12}>
                            <h2>{quizName}</h2>
                            <h6>Question {numQuestion} sur {questions.length}</h6>
                        </Grid>
                        <Grid item xs={12} style={{
                            height : (image !== "" ? "70vh" : "40vh"),
                            display : "flex",
                            flexDirection : "column",
                            justifyContent : "center",
                            alignItems : "center"
                        }}>
                            <h2>{questions.length > 0 ? questions[numQuestion - 1].intitule : ""}</h2>
                            {image !== "" && (<img alt="Question" src={questions.length > 0 && image} className={classes.QuestionImage}/>)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.ResponsesSide}>
                    <div style={{height : "100%", display : "flex", flexDirection : "column", justifyContent : "center"}}>
                        {questions.length > 0 && (
                            <div className={classes.AnswerButtonsContainer}>
                                <p
                                    className={selectedAnswer === 1 ? classes.SelectedAnswerButton : classes.AnswerButton}
                                    onClick={()=>handleClickAnswer(1)}
                                >
                                    {questions[numQuestion - 1].reponse1}
                                </p>

                                <p
                                    className={selectedAnswer === 2 ? classes.SelectedAnswerButton : classes.AnswerButton}
                                    onClick={()=>handleClickAnswer(2)}
                                >
                                    {questions[numQuestion - 1].reponse2}
                                </p>
                                {
                                    questions[numQuestion - 1].reponse3 && (
                                        <p
                                            className={selectedAnswer === 3 ? classes.SelectedAnswerButton : classes.AnswerButton}
                                            onClick={()=>handleClickAnswer(3)}
                                        >
                                            {questions[numQuestion - 1].reponse3}
                                        </p>
                                    )}
                                {
                                    questions[numQuestion - 1].reponse4 && (
                                        <p
                                            className={selectedAnswer === 4 ? classes.SelectedAnswerButton : classes.AnswerButton}
                                            onClick={()=>handleClickAnswer(4)}
                                        >
                                            {questions[numQuestion - 1].reponse4}
                                        </p>
                                    )
                                }
                            </div>
                        )}
                        <div>
                            <Button variant="contained" color="primary" style={{borderRadius : "25px", backgroundColor : (!(selectedAnswer > 0 && selectedAnswer <= 4) ? '#0000001e' : "#55bdbc"), color : (!(selectedAnswer > 0 && selectedAnswer <= 4) ? 'rgba(0,0,0,0.118)' : "white")}} size="large" className={classes.button} disabled={!(selectedAnswer > 0 && selectedAnswer <= 4)} onClick={handleValider}>
                            Valider
                        </Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
            {isConnected && (
                <a
                    style={{position:"fixed", bottom : "15px", left : "15px", fontWeight : "bold", color : "white"}}
                    onClick={async () => {
                        await firebaseConnector.firestore().collection("user_information").doc(userId).collection("eleves").doc(eleveId).collection("games").doc(idGameEvent).collection("events").add({
                            event : "quit",
                            time : firebaseConnector.firestore.Timestamp.fromDate(new Date())
                        });
                        history.push("/");
                    }}>Quitter</a>)}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >

                <div style={{display : "flex", justifyContent : "center", height : "100%", alignItems : "center"}}>
                    <div
                        style={{
                            backgroundColor : "white",
                            width : "60%",
                            height : "50%",
                            borderRadius : "25px",
                            padding : "20px"
                        }}
                    >
                        <div className={classes.modalDiv} style={{textAlign : "center", display : "flex", flexDirection : "column", justifyContent : "space-around", alignItems : "center", height : "100%"}}>
                            <h2 style={{fontWeight : "bold", color : "#55BDBC"}}>{modalTitle}</h2>
                            {modalText !== "" && <p style={{fontWeight : "bold"}}>{modalText}</p>}
                            {modalButtonText != "" && (<Button variant="contained" color="primary" style={{borderRadius : "25px", backgroundColor : "#55bdbc", color : "white"}} size="large" onClick={modalButtonHandleClose ? handleEndOfQuiz : handleClose}>{modalButtonText}</Button>)}

                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
