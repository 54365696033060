import React, {useEffect, useState} from 'react';
import {MDBSpinner} from 'mdbreact';

import '../../assets/css/dashboard.css'
import {
    Button,
    Checkbox,
    FormControl,
    Grid,
    Input,
    InputLabel,
    ListItemText,
    MenuItem, Modal,
    Select
} from "@material-ui/core";
import Question from "../../components/creation/quiz/Question";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory, useParams} from "react-router-dom";
import firebaseConnector from "../../api/firebase-connector";
import carreImage from '../../assets/img/carreimg.png'

export default function QuizCreation(){
    let history = useHistory();

    let { id } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [quizName, setQuizName] = useState("");
    const [questions, setQuestions] = useState([]);
    const [raisonEchecSave, setRaisonEchecSave] = useState("");
    const [open, setOpen] = useState(false);

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor : "white",
        },
        paper: {
            padding: "5px",
            textAlign: 'center',
            color: "black",
        },
        paperFooter : {
            padding: "15px",
        },
        logoText : {
            cursor : "pointer",
            padding : "15px",
            color : "#55BDBC",
            fontWeight : "bold",
            width : "100%",
            textAlign : "center",
            fontSize : "25px"
        },
        title : {
            padding : "15px",
            color : "#0F3F62",
            fontWeight : "bold",
            width : "100%",
            textAlign : "center",
            fontSize : "25px"
        },
        button : {
            position : "fixed",
            bottom : "5px",
            right : "5px",
            margin : "15px",
            color : "white",
            backgroundColor : "#55BDBC",
            "&:hover": {
                backgroundColor : "white",
                color : "#55BDBC",
            },
        }
    }));
    const classes = useStyles();

    const refreshQuestion = (numQuestion, goodAnswer, intitule, reponse1, reponse2, reponse3, reponse4, indice, imageUrl, imageFile, imageFirebasePath) => {

        console.log(numQuestion, goodAnswer, intitule, reponse1, reponse2, reponse3, reponse4, indice, imageUrl, imageFile, imageFirebasePath);
        console.log(numQuestion, goodAnswer, intitule, reponse1, reponse2, reponse3, reponse4, indice, imageUrl, imageFile, imageFirebasePath);
        var newQuestions = questions;
        if(reponse3 === "" && reponse4 !== ""){
            reponse3 = reponse4;
            reponse4 = "";
        }
        if(imageFile == undefined){
            newQuestions[numQuestion-1] = {
                goodAnswer : goodAnswer,
                intitule : intitule,
                reponse1 : reponse1,
                reponse2 : reponse2,
                reponse3 : reponse3,
                reponse4 : reponse4,
                indice : indice,
                imageUrl : newQuestions[numQuestion-1].imageUrl,
                imageFile : newQuestions[numQuestion-1].imageFile,
                imageFirebasePath : newQuestions[numQuestion-1].imageFirebasePath
            }
        }else{
            newQuestions[numQuestion-1] = {
                goodAnswer : goodAnswer,
                intitule : intitule,
                reponse1 : reponse1,
                reponse2 : reponse2,
                reponse3 : reponse3,
                reponse4 : reponse4,
                indice : indice,
                imageUrl : imageUrl,
                imageFile : imageFile,
                imageFirebasePath : imageFirebasePath
            }
        }

        setQuestions(newQuestions)

        checkIfValid();
    }

    const checkIfValid = () => {
        let valid = true;
        let raison = "";
        questions.forEach((question, index) => {
            let maxGoodAnswer = 2;
            if(question.reponse3 !== ""){
                if(question.reponse4 !== ""){
                    maxGoodAnswer = 4;
                }else{
                    maxGoodAnswer = 3;
                }
            }

            if(question.intitule === ""){
                valid = false;
                raison = "Veuillez selectionner un intitulé pour la question " + (index + 1);
            }else if(question.reponse1 === ""){
                valid = false;
                raison = "Veuillez saisir un texte pour la réponse 1 de la question " + (index + 1);
            }else if(question.reponse2 === ""){
                valid = false;
                raison = "Veuillez saisir un texte pour la réponse 2 de la question " + (index + 1);
            }else if(question.goodAnswer < 1 || question.goodAnswer > maxGoodAnswer){
                valid = false;
                raison = "Veuillez selectionner une bonne réponse pour la question " + (index + 1);
            }
        })
        setRaisonEchecSave(raison);
        return [valid, raison];
    }

    const handleAddQuestion = () => {
        setQuestions([
            ...questions, {
            goodAnswer : 0,
            intitule : "",
            reponse1 : "",
            reponse2 : "",
            reponse3 : "",
            reponse4 : "",
            indice : "",
            imageUrl : carreImage,
            imageFile : undefined,
            imageFirebasePath : undefined
        }]);
    }

    const handleSuppressQuestion = (numQuestion) => {
        var newQuestions = [...questions];
        newQuestions.splice(numQuestion-1, 1);
        setQuestions([...newQuestions])

    }

    const handleSaveAndQuit = async (saveAnyway = false) => {

        if(saveAnyway || checkIfValid()[0]){
            let idUser = firebaseConnector.auth().currentUser.uid;

            //Suppression
            let refQuestions = firebaseConnector.firestore()
                .collection("user_information").doc(idUser)
                .collection("games").doc(id)
                .collection("questions");

            const questionsCollection = await refQuestions.get();
            for(let question of questionsCollection.docs){
                await refQuestions.doc(question.id).delete();
            }

            Promise.all(questions.map(async (question, index) => {

                let refQuestion = refQuestions.doc("" + index);

                let url = "";
                if(question.imageFile !== undefined) {
                    let refStorage = firebaseConnector.storage().ref();
                    let refFile = refStorage.child(idUser + "/" + id + "/" + index + "." + question.imageFile.name.split(".")[question.imageFile.name.split(".").length - 1]);
                    refFile.put(question.imageFile)
                    url = refFile.fullPath;
                }else{
                    url = question.imageFirebasePath
                }

                if(url !== undefined) {
                    await refQuestion.set({
                        goodAnswer: question.goodAnswer,
                        intitule: question.intitule,
                        reponse1: question.reponse1,
                        reponse2: question.reponse2,
                        reponse3: question.reponse3,
                        reponse4: question.reponse4,
                        indice : question.indice,
                        imageFirebasePath : url
                    });
                }else{
                    await refQuestion.set({
                        goodAnswer: question.goodAnswer,
                        intitule: question.intitule,
                        reponse1: question.reponse1,
                        reponse2: question.reponse2,
                        reponse3: question.reponse3,
                        reponse4: question.reponse4,
                        indice : question.indice,
                        imageFirebasePath : ""
                    });
                }
            })).then(async () => {

                await firebaseConnector.firestore()
                    .collection("user_information").doc(idUser)
                    .collection("games").doc(id).set({valid : checkIfValid()[0]}, {merge : true})

                history.push("/");
            });
        }else{
            setOpen(true);
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {

    }, [questions])

    useEffect( ()=>{
        async function prepare() {
            let user = firebaseConnector.auth().currentUser;
            try{
                const quizDocRef = firebaseConnector.firestore().collection("user_information").doc(user.uid).collection("games").doc(id);

                const doc = await quizDocRef.get();
                setQuizName(doc.data().name);

                const questionsQuery = await quizDocRef.collection("questions").get();
                if(!questionsQuery.empty) {
                    Promise.all(questionsQuery.docs.map(async (question) => {
                        let retour = question.data();
                        if(retour.imageFirebasePath !== undefined && retour.imageFirebasePath !== "") {
                            retour.imageFile = undefined;
                            retour.imageUrl = await firebaseConnector.storage().ref(retour.imageFirebasePath).getDownloadURL();
                        }else{
                            retour.imageFile = undefined;
                            retour.imageFirebasePath = "";
                            retour.imageUrl = carreImage;
                        }
                        return retour;
                    })).then(newQuestions => {
                        setQuestions([...newQuestions])
                        setIsLoading(false)
                    })
                }else{
                    let newQuestions = [{
                        goodAnswer : 0,
                        intitule : "",
                        reponse1 : "",
                        reponse2 : "",
                        reponse3 : "",
                        reponse4 : "",
                        indice : "",
                        imageUrl : carreImage,
                        imageFile : undefined,
                        imageFirebasePath : undefined
                    }];
                    setQuestions([...newQuestions]);
                    setIsLoading(false);
                }
            }catch(e){

            }
        }
        prepare()
        checkIfValid();
    }, [id])

    if(isLoading)
        return (
            <div className="mt-5">
                <MDBSpinner/>
            </div>
        )

    return(
        <>
            <Grid container spacing={0} className={classes.root}>
                <Grid item xs={1}>
                    <div className={classes.paper}>
                        <p className={classes.logoText} onClick={() => handleSaveAndQuit()}>Pupylle</p>
                    </div>
                </Grid>
                <Grid item xs={10}>
                    <div className={classes.paper}>
                        <p className={classes.title}>{quizName}</p>
                    </div>
                </Grid>
                <Grid item xs={1}>
                    <div className={classes.paper}>
                    </div>
                </Grid>
                {questions.map((question, index) => (
                    <Question
                        key={index}
                        idQuiz={id}
                        numero={index+1}
                        callback={refreshQuestion}
                        startGoodAnswer={question.goodAnswer}
                        startIntitule={question.intitule}
                        startReponse1={question.reponse1}
                        startReponse2={question.reponse2}
                        startReponse3={question.reponse3}
                        startReponse4={question.reponse4}
                        startIndice={question.indice}
                        startImageUrl={question.imageUrl}
                        nextButtonFunc={index + 1 >= questions.length ? handleAddQuestion : undefined}
                        suppressButtonFunc={questions.length > 1 ? handleSuppressQuestion : undefined}
                    />
                ))}
            </Grid>
            <Button variant="contained" color="primary" style={{borderRadius : "25px", backgroundColor : "#55bdbc", color : "white"}} size="large" className={classes.button} onClick={() => handleSaveAndQuit()}>
                Sauvegarder et quitter
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >

                <div style={{display : "flex", justifyContent : "center", height : "100%", alignItems : "center"}}>
                    <div
                        style={{
                            backgroundColor : "white",
                            width : "60%",
                            borderRadius : "25px",
                            padding : "20px"
                        }}
                    >
                        <a style={{position : "relative", top : "-40px", left : "-35px", fontSize : "40px", color : "white"}} onClick={handleClose}>
                            <i className="fas fa-circle"/>
                            <i className="fas fa-times-circle" style={{position : "relative", left : "-45px", color : "red", fontSize : "50px"}}/>
                        </a>
                        <div style={{textAlign : "center"}}>
                            <h2 style={{fontWeight : "bold", color : "red"}}>Attention !</h2>
                            <p>{raisonEchecSave}</p>
                        </div>
                        <div style={{textAlign : "center", width : "100%", paddingBottom : "50px"}}>
                            <Grid container>
                                <Grid xs={0} md={1} lg={2} xl={5}/>
                                <Grid xs={12} md={5} lg={4} xl={1}>
                                    <Button variant="contained" color="primary" style={{margin : "5px", borderRadius : "25px", backgroundColor : "#55bdbc", color : "white"}} size="large" onClick={handleClose}>
                                    Rectifier
                                    </Button>
                                </Grid>
                                <Grid xs={12} md={5} lg={4} xl={1}>
                                    <Button variant="contained" color="primary" style={{margin : "5px", borderRadius : "25px", backgroundColor : "#55bdbc", color : "white"}} size="large" onClick={() => handleSaveAndQuit(true)}>
                                        Quitter quand même
                                    </Button>
                                </Grid>
                                <Grid xs={0} md={1} lg={2} xl={5}/>

                                <Grid xs={0} md={1} lg={2} xl={3}/>
                                <Grid xs={12} md={5} lg={4} xl={3}/>
                                <Grid xs={12} md={5} lg={4} xl={3}>
                                    <p style={{fontSize : "68%", fontWeight : "bold", color : "red"}}>(Le quiz ne sera pas jouable)</p>
                                </Grid>
                                <Grid xs={0} md={1} lg={2} xl={3}/>
                            </Grid>




                        </div>

                    </div>
                </div>
            </Modal>
        </>
    )
}
