import React from "react";
import {Route, Switch, Redirect, useHistory} from 'react-router-dom';

import MyCreations from '../section/dashboard/MyCreations';
import Create from '../section/dashboard/Create';
import firebaseConnector from "../api/firebase-connector";
import Suivi from "../section/dashboard/Suivi";
import SuiviPerso from "../section/suivi/SuiviPerso";

const RoutesApp = () => {
    let history = useHistory();
    let user = firebaseConnector.auth().currentUser;
    if(!user){
        window.location.href = "https://app.pupylle.fr";
        return (<p>Redirection...</p>)
    }else{
        return (
            <Switch>

                <Route path='/' exact component={MyCreations}/>
                <Route path='/create' exact component={Create}/>
                <Route path='/suivi' exact component={Suivi}/>
                <Route path='/suivi/:idEleve' exact component={SuiviPerso}/>

                <Route path="*">
                    <Redirect to="/" />
                </Route>
            </Switch>
        );
    }
};

export default RoutesApp;
