import React, {useEffect, useState} from 'react';

import '../../assets/css/dashboard.css'
import {useHistory, useParams} from "react-router-dom";
import firebaseConnector from "../../api/firebase-connector";
import {MDBAnimation, MDBCol, MDBIcon, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import Button from "@material-ui/core/Button";
import {Fab, Grid, Modal} from "@material-ui/core";
import {
    AddAlarm,
    CancelRounded,
    CheckCircleRounded,
    CheckRounded,
    ClearRounded,
    KeyboardArrowDownRounded,
    KeyboardArrowLeftRounded,
    KeyboardArrowUpRounded, PlayCircleFilledRounded, PlayCircleOutlineRounded
} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import imageTuto from "../../assets/img/tuto/Suivi-2.gif";
import imageTuto2 from "../../assets/img/tuto/Suivi-3.png";

export default function SuiviPerso(){
    let history = useHistory();

    let { idEleve } = useParams();

    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");

    const [gamesList, setGamesList] = useState("");
    const [detailsGame, setDetailsGame] = useState(undefined);
    const [detailsList, setDetailsList] = useState("");

    const [orderBy, setOrderBy] = useState("playTime");
    const [alphabeticalOrder, setAlphabeticalOrder] = useState(false);

    const [isPendingDetail, setIsPendingDetail] = useState("");

    const [open, setOpen] = React.useState(false);
    const [openTuto, setOpenTuto] = useState(false);

    const useStyles = makeStyles(() => ({
        returnButton : {
            cursor : "pointer",
            marginLeft : "25px",
            marginRight : "15px",
            padding : "5px",
            fontSize : "50px",
            backgroundColor : "#55BDBC",
            color : "white",
            borderRadius : "25px"
        },
        title : {
            fontSize : "40px",
            margin : "0px"
        },
        dataColumn : {
            width : "27%",
        },
        buttonColumn : {
            width : "19%",
            textAlign : "right"
        }
    }))
    const classes = useStyles();

    useEffect(() => {
        const prepare = async () => {
            let userId = firebaseConnector.auth().currentUser.uid;
            let refTutos = firebaseConnector.firestore().collection("user_information").doc(userId).collection("tutorials").where("type", "==", "SuiviPerso");
            let queryTutos = await refTutos.get();
            if(queryTutos.empty){
                setOpenTuto(true);
            }

            let refEleve = firebaseConnector.firestore().collection("user_information").doc(userId).collection("eleves").doc(idEleve);
            let eleveQuery = await refEleve.get();

            setNom(eleveQuery.data().nom);
            setPrenom(eleveQuery.data().prenom);

            let refEleveGames = firebaseConnector.firestore().collection("user_information").doc(userId).collection("eleves").doc(idEleve).collection("games").orderBy(orderBy, alphabeticalOrder ? 'asc' : 'desc');
            let eleveGamesQuery = await refEleveGames.get();

            let eleveGames = [];
            for(let game of eleveGamesQuery.docs){
                let myGame = game.data();
                myGame.id = game.id;
                eleveGames.push(myGame);
            }
            setGamesList([...eleveGames]);

        }

        prepare();
    }, [alphabeticalOrder, idEleve, orderBy])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleHeaderClick = (value) => {
        if (value === orderBy) {
            setAlphabeticalOrder(!alphabeticalOrder)
        }else{
            setAlphabeticalOrder(true);
            setOrderBy(value)
        }
    }

    const handleDetail = async (game) => {
        if(!isPendingDetail) {
            setIsPendingDetail(true);

            let userId = firebaseConnector.auth().currentUser.uid;
            let refEvents = firebaseConnector.firestore().collection("user_information").doc(userId).collection("eleves").doc(idEleve).collection("games").doc(game.id).collection("events").orderBy("time");
            let eventsQuery = await refEvents.get();

            let events = [];
            if(!eventsQuery.empty){
                for(let event of eventsQuery.docs){
                    events.push(event.data());
                }
            }
            setDetailsList([...events]);
            setDetailsGame(game);

            handleOpen();
            setIsPendingDetail(false);
        }
    }

    const handleCloseTuto = async () => {
        let userId = firebaseConnector.auth().currentUser.uid;
        await firebaseConnector.firestore()
            .collection("user_information").doc(userId)
            .collection("tutorials").add({
                type : "SuiviPerso"
            });
        setOpenTuto(false);
    }

    const formatFirebaseTimestamp = (timestamp) => {
        let dateObj = timestamp.toDate();

        let jour = dateObj.getDate();
        if(jour < 10){
            jour = "0" + jour;
        }

        let mois = dateObj.getMonth() + 1;
        if(mois < 10){
            mois = "0" + mois;
        }

        let hours = dateObj.getHours();
        if(hours < 10){
            hours = "0" + hours;
        }

        let minutes = dateObj.getMinutes() + 1;
        if(minutes < 10){
            minutes = "0" + minutes;
        }

        return jour + "/" + mois + "/" + dateObj.getFullYear() + " " + hours + ":" + minutes;
    }

    return (
        <div>
            <div>
                <MDBRow>
                    <MDBCol size="12" className="pl-0">
                        <MDBRow>
                            <MDBCol size="11">
                                <div style={{display : "flex", alignItems:"center", marginBottom : "25px", marginTop : "15px"}}>
                                    <KeyboardArrowLeftRounded className={classes.returnButton} onClick={() => history.push("/suivi")}/>
                                    <h1 className={classes.title}>Suivi {prenom} {nom}</h1>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
            <div className="dashboard-content card z-depth-0dot5" style={{padding: "1rem"}}>
                <MDBAnimation type="fadeIn">
                    {gamesList.length > 0 ? (
                        <MDBTable className="table-head-bold" style={{padding: "0.55rem"}}>
                            <MDBTableHead color="primary">
                                <tr style={{paddingLeft: "2rem"}}>
                                    <th><a onClick={() => handleHeaderClick("name")}>Nom du jeu {orderBy === "nom" ? alphabeticalOrder ? (<KeyboardArrowDownRounded/>) : (<KeyboardArrowUpRounded/>) : <KeyboardArrowUpRounded style={{color : "white"}}/>}</a></th>
                                    <th><a onClick={() => handleHeaderClick("playTime")}>Date {orderBy === "playTime" ? alphabeticalOrder ? (<KeyboardArrowDownRounded/>) : (<KeyboardArrowUpRounded/>) : <KeyboardArrowUpRounded style={{color : "white"}}/>}</a></th>
                                    <th><a onClick={() => handleHeaderClick("nbErreur")}>Nb erreurs {orderBy === "nbErreur" ? alphabeticalOrder ? (<KeyboardArrowDownRounded/>) : (<KeyboardArrowUpRounded/>) : <KeyboardArrowUpRounded style={{color : "white"}}/>}</a></th>
                                    <th><a onClick={() => handleHeaderClick("success")}>Terminé {orderBy === "success" ? alphabeticalOrder ? (<KeyboardArrowDownRounded/>) : (<KeyboardArrowUpRounded/>) : <KeyboardArrowUpRounded style={{color : "white"}}/>}</a></th>
                                    <th> </th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {
                                    gamesList.length > 0 && (gamesList.map((game,index) => (
                                        <tr key={index}>
                                            <th className={classes.dataColumn} style={{verticalAlign : "middle"}}>{game.name}</th>
                                            <th className={classes.dataColumn} style={{verticalAlign : "middle"}}>{formatFirebaseTimestamp(game.playTime)}</th>
                                            <th className={classes.dataColumn} style={{verticalAlign : "middle"}}>{game.nbErreur}</th>
                                            <th className={classes.dataColumn} style={{verticalAlign : "middle"}}>{game.success ? <CheckRounded style={{color : "green"}}/> : <ClearRounded style={{color : "red"}}/>}</th>
                                            <th className={classes.buttonColumn}>
                                                <Button variant="contained" color="primary" style={{borderRadius : "25px", backgroundColor : "#55bdbc", color : "white"}} size="large" onClick={() => handleDetail(game)}>
                                                    {isPendingDetail && <MDBIcon icon="spinner" pulse fixed/>} Détails
                                                </Button>
                                            </th>
                                        </tr>
                                    )))
                                }
                            </MDBTableBody>
                        </MDBTable>
                    ) : <h4 style={{textAlign : "center"}}>Pas encore de résultat 😕</h4>}
                </MDBAnimation>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >

                <div style={{display : "flex", justifyContent : "center", height : "100%", alignItems : "center"}}>
                    <div
                        style={{
                            backgroundColor : "white",
                            width : "75%",
                            maxHeight : "90%",
                            borderRadius : "25px",
                            padding : "20px",
                        }}
                    >
                        <a style={{position : "relative", top : "-40px", left : "-35px", fontSize : "40px", color : "white"}} onClick={handleClose}>
                            <i className="fas fa-circle"/>
                            <i className="fas fa-times-circle" style={{position : "relative", left : "-45px", color : "red", fontSize : "50px"}}/>
                        </a>
                        <div style={{display : "flex", flexDirection : "column", maxHeight : "calc(80vh - 60px)", overflowY : "auto"}}>
                            <h2 style={{fontWeight : "bold", color : "#0F3F62", textAlign : "center"}}>Détails</h2>
                            <h4>
                                <span style={{fontWeight : "bold"}}>Nom de l'exercice : </span> {detailsGame && detailsGame.name}
                            </h4>
                            <br/>
                            <h4>
                                <span style={{fontWeight : "bold"}}>Déroulé : </span>
                            </h4>
                            {
                                detailsList.length > 0 && detailsList.map((event, index) => (
                                    <div key={index} style={{display : "flex", alignItems : "center", marginBottom : "20px"}}>
                                        {event.event === "start" && (<div style={{display : "flex", alignItems : "center", color : "#55BDBC"}}><PlayCircleFilledRounded/><span style={{fontWeight : "bold", marginLeft : "15px"}}>Début de l'exercice</span></div>)}
                                        {event.event === "good" && (<CheckRounded style={{color : "green"}}/>)}
                                        {event.event === "wrong" && (<ClearRounded style={{color : "red"}}/>)}
                                        {event.event === "success" && (<div style={{display : "flex", alignItems : "center", color : "#55BDBC"}}><CheckCircleRounded/><span style={{fontWeight : "bold", marginLeft : "15px"}}>Exercice réussi !</span></div>)}
                                        {(event.event === "quit" && index+1 >= detailsList.length && detailsList[index-1].event !== "success") && (<div style={{display : "flex", alignItems : "center", color : "red"}}><CancelRounded/><span style={{fontWeight : "bold", marginLeft : "15px"}}>La page a été quitté</span></div>)}
                                        {(event.event === "good" || event.event === "wrong") && (
                                            <p style={{margin : "0px 0px 0px 15px"}}>
                                                <span style={{fontSize : "60%"}}><span style={{fontWeight : "bold"}}>Question {event.numQuestion} : </span> {event.part1}</span><br/>
                                                {event.part2} {event.event === "wrong" && (<span style={{color : "#55BDBC"}}>({event.part3})</span>)}
                                            </p>
                                        )
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={openTuto}
                onClose={handleCloseTuto}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >

                <div style={{display : "flex", justifyContent : "center", height : "100%", alignItems : "center"}}>
                    <div
                        style={{
                            backgroundColor : "white",
                            width : "80%",
                            maxWidth : "1280px",
                            maxHeight : "90vh",
                            borderRadius : "25px",
                            padding : "20px",
                            position:"relative"
                        }}
                    >
                        <a style={{position : "relative", top : "-40px", left : "-35px", fontSize : "40px", color : "white"}} onClick={handleCloseTuto}>
                            <i className="fas fa-circle"/>
                            <i className="fas fa-times-circle" style={{position : "relative", left : "-45px", color : "red", fontSize : "50px"}}/>
                        </a>
                        <div style={{textAlign : "left", marginTop : "-40px", maxHeight : "calc(80vh + 20px)", overflowY : "auto"}}>
                            <div>
                                <Grid container style={{width : "90%", marginBottom : "20px", marginLeft : "5%"}}>
                                    <Grid xs={6}>
                                        <h2 style={{color : "#0F3F62", fontWeight : "bold"}}>Tutoriel</h2>
                                        <h3 style={{color : "#55BDBC"}}>Suivi personnel</h3>
                                    </Grid>
                                    <Grid
                                        xs={6}
                                        style={{display : "flex", justifyContent : "center", alignItems : "center", flexDirection : "column", cursor : "pointer"}}
                                        onClick={() => {window.open("https://www.youtube.com/watch?v=WfHm10rVOLY&list=PLiyxvOGtiPXVpUGLu1tqTj0OwwhelripS&index=5", "_blank")}}
                                    >
                                        <PlayCircleOutlineRounded style={{fontSize : "50px"}}/>
                                        <h3 style={{color : "#0F3F62", fontWeight : "bold"}}>Voir la vidéo</h3>
                                    </Grid>
                                    <Grid xs={12}>
                                        <p style={{paddingTop : "30px"}}>
                                            Dans "Suivi personnel" vous retrouverez la liste des jeux réalisés.
                                        </p>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6} lg={6} xl={6} style={{padding : "30px", display : "flex", alignItems : "center"}}>
                                        <p style={{textAlign : "center", margin : "0px"}}>
                                            <img src={imageTuto} style={{width : "90%", boxShadow : "0px 5px 8px 1px rgba(0, 0, 0, 0.2)", borderRadius : "25px"}}/>
                                        </p>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <p style={{paddingTop : "30px"}}>
                                            <span style={{fontWeight : "bold", fontSize : "115%"}}>Voir les détails</span><br/><br/>
                                            Le tableau vous indique le nom du jeu, la date, si le jeu a été fini ainsi que le nombre d'erreur commises lors de la partie.<br/>
                                            Vous pouvez voir le détail du jeu en cliquant sur le bouton "Détails" au bout de la ligne.<br/>
                                            Une fenêtre va s'ouvrir vous affichant par ordre chronologique les mauvaises et les bonnes réponses comme vous pouvez le voir sur l'image ci dessous.

                                        </p>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} style={{padding : "30px", display : "flex", alignItems : "center"}}>
                                        <p style={{textAlign : "center", margin : "0px"}}>
                                            <img src={imageTuto2} style={{width : "90%", boxShadow : "0px 5px 8px 1px rgba(0, 0, 0, 0.2)", borderRadius : "25px"}}/>
                                        </p>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Fab color="primary" aria-label="add" style={{position : "fixed", bottom : "15px", right : "15px", cursor : "pointer"}} onClick={() => {
                setOpenTuto(true);
            }}>
                <span style={{fontSize : "200%"}}>?</span>
            </Fab>
        </div>
    )
}
